<template>
  <div class="report">
    <div class="report-header">
      <div class="d-flex flex-row justify-content-between">
        <div class="datetime">
          <div>{{ date }} {{ time }}</div>
        </div>
        <div>
          <img src="./../assets/fwd-icon.svg" alt="" class="fwd-icon" />
        </div>
      </div>
      <div class="name">
        {{ userProfile.name }}
      </div>
    </div>
    <div class="scan-result">
      <!-- General Wellness -->
      <div class="general-wellness">
        <div class="flex-grow w-100">
          <div class="wellness-title">{{ t("health_report.general_wellness") }}</div>
          <div class="wellness-bar">
            <div class="progress">
              <div
                class="progress-bar bg-fwd-orange"
                role="progressbar"
                :style="`width: ${vitalSign.generalWellness}%`"
                :aria-valuenow="vitalSign.generalWellness"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="wellness-title">{{ vitalSign.generalWellness.toFixed(0) }}/100</div>
        </div>
        <div class="wellness-score">{{ vitalSign.generalWellness.toFixed(0) }}</div>
      </div>

      <!-- Other Metrics -->
      <div class="health-metrics">
        <div class="metrics-result">
          <div v-for="(metric, index) in $tm('health_report.metrics')" :key="index" class="metric background-grey">
            <img :src="require('../assets/' + metric.image)" alt="" class="metric-image" />
            <div class="w-100 d-flex flex-column justify-content-between">
              <div class="metric-name">{{ metric.name }}</div>
              <div class="fwd-orange">
                <div v-if="metric.tag == 'stress'" class="metric-data">
                  {{ vitalSign[metric.tag].toFixed(1) }}{{ metric.unit }}
                </div>
                <div v-else-if="metric.tag == 'facialSkinAge'" class="metric-data">
                  {{ vitalSign[metric.tag].toFixed(0) }}
                </div>
                <div v-else class="metric-data">{{ vitalSign[metric.tag] }} {{ metric.unit }}</div>
              </div>
            </div>
            <div class="category" v-if="metric.tag !== 'facialSkinAge'">
              <div class="category-circle" :style="{ backgroundColor: colors[getCategory(metric.tag)] }"></div>
            </div>
          </div>
        </div>
        <div class="health-analysis background-grey background-grey">
          <u>{{ t("health_report.health_analysis.title") }}</u>
          <div class="condition fwd-orange">{{ t("health_report." + categories[getCategory("generalWellness")]) }}</div>
          <div class="analysis" v-html="healthAnalysis"></div>
        </div>
      </div>

      <!-- Drink Recommendation -->
      <div class="recommendation-container background-grey">
        <img
          :src="require('./../assets/coffee_photos/' + t(`health_report.recommendations.${drinkRecommendation}.image`))"
          alt=""
          class="coffee-image"
        />
        <div class="recommendation-title">
          <u>{{ t("health_report.recommendations.title") }}</u>
        </div>
        <div class="recommendation-subtitle">{{ t("health_report.recommendations.subtitle") }}</div>
        <div class="recommendation-name fwd-orange">
          {{ t(`health_report.recommendations.${drinkRecommendation}.name`) }}
        </div>
        <div class="recommendation-analysis">
          {{ t(`health_report.recommendations.${drinkRecommendation}.analysis`) }}
        </div>
      </div>

      <!-- Survey -->
      <div class="survey-container background-grey">
        <div class="d-flex justify-content-center flex-column g-18">
          <div class="hashtag fwd-orange">#WeListenAtFwd</div>
          <div class="qr-text">{{ t("health_report.qr_text") }}</div>
        </div>
        <div>
          <img src="./../assets/survey-qr.png" alt="" class="qr-code" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../../user/company-user-manager.ts";
export default {
  setup() {
    const { t, tm } = useI18n();
    return {
      t,
      tm,
    };
  },
  props: {
    vitalSign: Object,
    userProfile: Object,
  },
  data() {
    return {
      categories: ["room_for_improvement", "good", "excellent"],
      recommendation: {
        generalWellness: ["jasmine_tea", "earl_grey", "english_breakfast_tea", "oj"],
        bloodPressure: ["black"],
        stress: ["cappuccino", "apple_juice", "latte"],
      },
      colors: ["#B30909", "#E87722", "#038752"],
    };
  },
  computed: {
    time() {
      const datetime = new Date(newUserManager.currentUser.currentUser.lastHealth.datetime);
      return `${datetime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}`;
    },
    date() {
      const datetime = new Date(newUserManager.currentUser.currentUser.lastHealth.datetime);
      return `${datetime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })}`;
    },
    healthAnalysis() {
      const stress = this.categories[this.getCategory("stress")];
      const bloodPressure = this.categories[this.getCategory("bloodPressure")];
      if (stress == "room_for_improvement" && bloodPressure == "room_for_improvement") {
        return this.t("health_report.health_analysis.room_for_improvement.both");
      } else if (stress == "room_for_improvement") {
        return this.t("health_report.health_analysis.room_for_improvement.stress");
      } else if (bloodPressure == "room_for_improvement") {
        return this.t("health_report.health_analysis.room_for_improvement.bloodPressure");
      } else {
        if (stress == "good" || bloodPressure == "good") {
          return this.t("health_report.health_analysis.good");
        } else {
          return this.t("health_report.health_analysis.excellent");
        }
      }
    },
    drinkRecommendation() {
      const vitalsConditions = {
        generalWellness: this.getCategory("generalWellness"),
        bloodPressure: this.getCategory("bloodPressure"),
        stress: this.getCategory("stress"),
      };
      const drinkRecommendation = [];
      if (
        vitalsConditions.generalWellness == 2 &&
        vitalsConditions.bloodPressure == 2 &&
        vitalsConditions.stress == 2
      ) {
        drinkRecommendation.push(...this.recommendation.generalWellness);
      } else {
        const minIndex = Math.min(...Object.values(vitalsConditions));
        const recommendedVitalSigns = Object.keys(vitalsConditions).filter(
          (vital) => vitalsConditions[vital] == minIndex
        );
        recommendedVitalSigns.map((vital) => {
          drinkRecommendation.push(...this.recommendation[vital]);
        });
      }
      return drinkRecommendation[Math.floor(Math.random() * drinkRecommendation.length)];
      // debug
      // return drinkRecommendation[8]
    },
  },
  methods: {
    getCategory(tag) {
      if (tag == "stress") {
        if (this.vitalSign.stress < 2) {
          return 2;
        } else if (this.vitalSign.stress >= 2 && this.vitalSign.stress < 3) {
          return 1;
        } else {
          return 0;
        }
      } else if (tag == "bloodPressure") {
        const bp = this.vitalSign.bloodPressure.split("/");
        const bpSystolic = Number(bp[0]);
        const bpDiastolic = Number(bp[1]);

        if (bpSystolic >= 70 && bpSystolic < 90 && bpDiastolic >= 40 && bpDiastolic < 60) {
          return 0;
        } else if (bpSystolic <= 120 && bpDiastolic <= 80) {
          return 2;
        } else if (bpSystolic <= 140 && bpDiastolic <= 90) {
          return 1;
        } else {
          return 0;
        }
      } else if (tag == "generalWellness") {
        if (this.vitalSign[tag] < 40) {
          return 0;
        } else if (this.vitalSign[tag] >= 40 && this.vitalSign[tag] < 70) {
          return 1;
        } else {
          return 2;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
.analysis {
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  font-weight: 350;
}
.background-grey {
  background-color: #f8f9f9;
  border-radius: 4px;
}
.category {
  display: flex;
  align-items: center;
}
.category-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.coffee-image {
  width: 76px;
  height: 76px;
  margin-bottom: 5px;
  border-radius: 50%;
  float: right;
}
.datetime {
  text-align: right;
  font-size: 8px;
  line-height: 8px;
  font-weight: 350;
  margin-top: 1px;
  margin-left: 3px;
}
.fwd-icon {
  width: 60px;
}
.hashtag {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}
.health-analysis {
  display: flex;
  flex-direction: column;
  padding: 12px 11px;
  align-items: center;
  line-height: 12px;
  text-align: center;
  width: 60%;
  justify-content: space-between;
}
.health-metrics {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 5px;
}
.general-wellness {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}
.metric {
  display: flex;
  flex-direction: row;
  padding: 11px 15px;
  gap: 8px;
  font-size: 8px;
  line-height: 8px;
}
.metric-data {
  font-size: 12px;
  line-height: 12px;
  font-weight: 350;
}
.metric-image {
  width: 27px;
  height: 27px;
}
.metric-name {
  font-size: 10px;
  font-weight: 350;
  line-height: 10px;
}
.metrics-result {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 8px;
  flex-grow: 1;
}
.name {
  font-size: 58px;
  line-height: 58px;
  font-weight: 700;
}
.progress {
  height: 10px;
}
.progress-bar {
  height: 10px;
  border-radius: 12px;
}
.qr-code {
  width: 66px;
}
.qr-text {
  font-size: 10px;
  font-weight: 350;
  line-height: 10px;
}
.recommendation-container {
  margin-top: 10px;
  padding: 12px 16px 12px 12px;
}
.recommendation-analysis {
  font-size: 10px;
  line-height: 12px;
  font-weight: 350;
}
.recommendation-name {
  font-size: 16px;
  margin-bottom: 19px;
  line-height: 16px;
  font-weight: 700;
}
.recommendation-title {
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 15px;
}
.recommendation-subtitle {
  font-weight: 350;
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 6px;
}
.report {
  width: 420px;
  height: 595px;
  background-color: white;
}
.report-header {
  display: flex;
  flex-direction: column;
  margin: 2px;
  gap: 5px;
}
.scan-result {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
}
.survey-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 19px 10px 12px;
  margin-top: 10px;
  gap: 46px;
}
.wellness-bar {
  margin-bottom: 10px;
}
.wellness-score {
  font-size: 58px;
  line-height: 58px;
  font-weight: 700;
}
.wellness-title {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px;
}
</style>