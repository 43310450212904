<template>
  <div class="description">
    <div class="d-flex justify-content-center flex-column align-items-center g-24">
      <div class="print-reminder" v-html="t('scan_result.print_reminder')"></div>
      <div class="d-flex flex-row g-24">
        <button type="button" class="continue-button-clear fwd-orange" @click="newScan">
          {{ t("button.try_again") }}
        </button>
        <button
          type="button"
          :class="`continue-button mx-2 bg-fwd-orange ${loading ? 'loading-button' : ''}`"
          @click="printReport"
          :disabled="loading"
        >
          <img src="../assets/loading.gif" alt="" v-if="loading" class="loading-indicator" />
          {{ t("button.print_result") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  emits: ["removeProfile"],
  mounted() {
    window.addEventListener("beforeprint", this.finishLoading);
  },
  unmounted() {
    window.removeEventListener("beforeprint", this.finishLoading);
  },
  methods: {
    finishLoading() {
      this.loading = false;
    },
    newScan() {
      this.$emit("removeProfile");
    },
    printReport() {
      print();
      this.loading = true;
      setTimeout(() => {
        if (this.loading) {
          this.loading = false;
          alert("Printing is unsuccessful. Please try again.")
        }
      }, 10000);
    },
  },
};
</script>

<style scoped>
.continue-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.loading-button {
  opacity: 0.3;
}
.loading-indicator {
  width: 20px;
}
.print-reminder {
  font-weight: 500;
}
</style>
