<template>
  <div class="container">
    <div class="condition-list">
      <div class="d-flex justify-content-center g-37">
        <div class="condition">
          <condition-icon class="icon" :fulfilled="conditions.brightness" />
          <div>
            {{ t("message.conditions.lighting") }}
          </div>
        </div>
        <div class="condition">
          <condition-icon class="icon" :fulfilled="conditions.distance" />
          <div>
            {{ t("message.conditions.distance") }}
          </div>
        </div>
        <div class="condition">
          <condition-icon class="icon" :fulfilled="conditions.centered" />
          <div>
            {{ t("message.conditions.centered") }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center g-37">
        <div class="condition">
          <condition-icon class="icon" :fulfilled="conditions.movement" />
          <div>
            {{ t("message.conditions.movement") }}
          </div>
        </div>
        <div class="condition">
          <condition-icon class="icon" :fulfilled="conditions.serverReady" />
          <div>
            {{ t("message.conditions.server_ready") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import ConditionIcon from "./condition-icon.vue";

export default {
  name: "conditions-hud",
  props: ["conditions"],
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  computed: {
    isOK() {
      return Object.values(this.conditions).reduce((previousValue, currentValue) => {
        return previousValue && currentValue;
      }, true);
    },
  },
  components: {
    ConditionIcon,
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  text-align: center;
  display: inline-block;
  padding: 0;
}
.condition {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0;
}
.g-37 {
  gap: 37px;
}
.condition-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.icon {
  margin-left: 0em;
}
@media (max-width: 2000px) {
  .condition {
    font-size: 25px;
  }
  .condition-list {
    line-height: 45px;
  }
}
</style>
