<template>
  <div class="" id="userProfileInput" v-if="!profileFilled && showModal">
    <div class="modal-content">
      <div class="modal-header bg-fwd-orange">
        <button type="button" class="btn-close" @click="closeModal"></button>
        <div class="question fwd-dark-green">
          {{ t("user_input.title") }}
        </div>
      </div>
      <div class="modal-body d-flex flex-column align-items-center g-24">
        <div class="fwd-orange">
          <!-- title -->

          <form v-on:submit="saveSettings">
            <!-- Name -->
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input
                class="input fwd-dark-green"
                id="nameInput"
                @input="checkDisabled"
                :placeholder="t('user_input.question')"
                maxlength="13"
                v-model="this.info.name"
              />
            </div>
          </form>
        </div>
        <button type="button" class="continue-button bg-fwd-orange" :disabled="disabled" @click="updateProfile">
          {{ t("button.continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "user-profile-input",
  props: {
    form: Object,
    showModal: Boolean,
  },
  setup() {
    const { t, locale } = useI18n();
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    return {
      profileFilled,
      updateProfileFilled,
      t,
      locale,
    };
  },
  emits: ["updateProfile", "updateModal"],
  mounted() {
    setTimeout(() => {
      this.checkDisabled();
    }, 300);
  },
  components: {},
  computed: {
    age() {
      const age = [];
      for (var i of Array(9).keys()) {
        if (i < 2) {
          continue;
        } else if (i == 2) {
          age.push("18-29");
        } else {
          age.push(`${i * 10}-${i * 10 + 9}`);
        }
      }
      age.push("90 or above");
      return age;
    },
  },
  methods: {
    loadForm() {
      this.info = this.form;
      if (this.debug) {
        this.info = {
          name: "DebugUser",
          email: "debug@panoptic.ai",
          phoneNumber: "12345678",
          birthday: "2019-08-19",
          gender: "male",
          height: 170,
          weight: 70,
          unitSystem: false,
          countries: "Hong Kong",
          smoker: false,
          hypertension: false,
          bloodPressureMedication: false,
          diabetic: "No",
          heartDisease: false,
          depression: false,
        };
      }
    },
    checkDisabled() {
      let check = false;
      Object.keys(this.info).map((data) => {
        if (this.info[data] === null || this.info[data] === "") {
          check = true;
          return;
        }
      });
      this.disabled = check;
    },
    updateProfile() {
      this.$emit("updateProfile", this.info);
      this.closeModal();
    },
    closeModal() {
      this.$emit("updateModal", false);
    },
  },
  watch: {
    form(newValue) {
      this.info = newValue;
      this.checkDisabled();
    },
  },
  data() {
    return {
      info: {
        name: "",
      },
      disabled: true,
      debug: this.$root.debugMode,
    };
  },
};
</script>

<style scoped>
::placeholder {
  color: #CED8D6;
}
#userProfileInput {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(98vh - 56px);
  width: 100vw;
  padding: 56px 80px;
  position: absolute;
}
.modal {
  overflow: hidden;
  border-radius: 10px;
}
.modal-close-button {
  position: fixed;
  top: 4.5%;
  right: 3.5%;
}
/* Modal Content/Box */
.modal-content {
  position: grid;
  margin: 0% auto;
  padding: 5px;
  max-width: 80%;
  overflow: hidden;
  border-radius: 24px;
  padding: 0px;
  border: none;
  background-color: transparent;
}
.modal-header {
  display: flex;
  justify-content: center;
  border: none;
  font-size: 18px;
  font-weight: 600;
  padding: 140px 0;
}
.modal-body {
  justify-content: center;
  padding: 2px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 36px 36px;
  padding: 110px 120px 116px 120px;
}
.form-label {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: 0.25px;
  margin-bottom: 30px;
}
.btn-close {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 40px;
}
.content {
  /* border-style: dashed;
    border: 2px purple; */
  padding: 1%;
}
.content-title {
  font-weight: bolder;
  font-size: x-large;
}
.photo-div {
  /* border: 1px red;
    border-style: solid; */
  margin: 0 auto;
  padding: 10px;
}
.photo {
  max-width: 80%;
  margin: 0 auto;
}
.center-item {
  display: grid;
  justify-content: center;
}
.paragraph-div {
  /* border: 1px blue;
    border-style: solid; */
  text-align: justify;
  padding: 5px;
}
.paragraph-title {
  font-weight: bolder;
  font-size: x-large;
}
.paragraph-content {
  font-size: medium;
  width: 100%;
}
.modal-footer {
  bottom: 0px;
  height: 10%;
  justify-content: center;
  border: none;
  padding-top: 0;
}
.logo {
  width: 48px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: left;
}
.subtitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.5;
}
.question {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  letter-spacing: 0.25px;
  text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
}
.input {
  border: 3px solid #DBDFE1;
  border-radius: 4px;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  font-size: 31px;
  font-weight: 500;
  padding: 10px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
option:hover {
  background-color: lightgrey;
  border-radius: 50px;
}

option:checked {
  border-radius: 50px;
}

.form-select:focus {
  border-radius: 50px;
}

@media (max-width: 1800px) {
  .modal-dialog {
    max-width: 80%;
  }
  .form-label {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .title {
    font-size: 16px;
  }
  .subtitle {
    font-size: 12px;
  }
  .question {
    font-size: 25px;
  }
  .form-select {
    text-align: center;
  }
  .modal-header {
    padding: 24px;
  }
  .btn-close {
    font-size: 20px;
    top: 20px;
  }
  .input {
    width: 229px;
    font-size: 31px;
    line-height: 33px;
  }
  .modal-body {
    height: fit-content;
    padding: 24px;
    padding-bottom: 48px;
  }
  .form-select:focus {
    border-radius: 20px;
    overflow: hidden;
  }
}
</style>


