<template>
  <div class="start-content-container">
    <div class="start-content d-flex flex-column overflow-scroll" v-if="!showTC">
      <div class="overflow-scroll">
        <div class="header w-100 m-0 bg-fwd-orange">
          <p class="title fwd-dark-green m-0 p-0" v-html="t('start_content.title')"></p>
          <p class="subtitle fwd-dark-green m-0 p-0">{{ t("start_content.subtitle") }}</p>
          <div class="d-flex flex-row metrics-container flex-wrap" :style="{ padding: '0 42px' }">
            <div
              v-for="(metric, index) in $tm('start_content.metrics')"
              :key="index"
              class="col text-center d-flex flex-column align-items-center metrics"
            >
              <img :src="require('' + metric.image)" alt="" class="metrics-image" />
              <p class="fwd-dark-green metrics-name m-0 p-0" v-html="metric.name"></p>
            </div>
          </div>
        </div>
        <div class="description">
          <div class="d-flex steps-container flex-column">
            <div
              class="d-flex flex-row align-items-center fwd-dark-green g-24"
              v-for="(step, index) in $tm('start_content.steps')"
              :key="index"
            >
              <div class="number border-fwd-dark-green">
                {{ index + 1 }}
              </div>
              <div v-html="step" class="col steps"></div>
            </div>
          </div>
          <div class="text-center w-100 fwd-dark-green">
            <vital-sign-button
              class="continue-button bg-fwd-orange"
              :enable="enable"
              @startMeasurement="$emit('startMeasurement')"
              @removeProfile="removeProfile"
              @updateModal="updateModal"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="start-content d-flex flex-column overflow-scroll">
      <div class="header w-100 m-0 bg-fwd-orange">
        <div class="subtitle">
          {{ t("start_content.terms_condition.title") }}
        </div>
      </div>
      <div class="description">
        <div class="fwd-dark-green overflow-scroll flex-shrink content" @scroll="onScroll">
          <p>{{ t("start_content.terms_condition.opening") }}</p>
          <ol class="text-start">
            <li v-for="content in $tm('start_content.terms_condition.content')" :key="content" v-html="content"></li>
          </ol>
          <p>{{ t("start_content.terms_condition.closing") }}</p>
          <p>{{ t("start_content.terms_condition.signing") }}</p>
        </div>
        <div class="text-center w-100 fwd-dark-green">
          <div class="d-flex justify-content-center g-24">
            <button type="button" class="continue-button-clear fwd-orange" @click="updateShowTC(false)">
              {{ t("button.back") }}
            </button>
            <button
              type="button"
              class="bg-fwd-orange continue-button"
              @click="updateShowTC(true)"
              :disabled="disabled"
            >
              {{ t("button.agree") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { inject } from "vue";
import vitalSignButton from "./vital-sign-button-js.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    const { profileFilled, updateProfileFilled } = inject("profileFilled");

    return {
      profileFilled,
      updateProfileFilled,
      t,
      locale,
    };
  },
  data() {
    return {
      disabled: true,
    };
  },
  props: {
    vsStatus: String,
    showTC: Boolean,
    enable: Object,
  },
  emits: ["updateShowTC", "startMeasurement", "removeProfile", "updateModal"],
  computed: {
    startCountdown() {
      return this.conditions ? Object.values(this.conditions).every((item) => item) : false;
    },
  },
  methods: {
    updateShowTC(val) {
      this.$emit("updateShowTC", val);
      if (val) {
        this.startMeasurement();
      }
    },
    startMeasurement() {
      this.$emit("startMeasurement");
    },
    removeProfile() {
      this.$emit("removeProfile");
    },
    updateModal(val) {
      this.$emit("updateModal", val);
    },
    onScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if (Math.round(scrollTop + offsetHeight) >= scrollHeight) {
        this.disabled = false;
      }
    },
  },
  components: {
    vitalSignButton,
  },
  watch: {
    profileFilled(val) {
      if (val) {
        this.$emit("startMeasurement");
        this.state = "measuring";
      }
    },
  },
};
</script>

<style scoped>
div::-webkit-scrollbar {
  display: none;
}

.back-button {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.231689px;
  padding: 0;
}
.content {
  font-size: 20px;
  font-weight: 350;
}
.continue-button:disabled {
  opacity: 0.7;
  background-color: #e87722;
  border: none;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}
.warning-icon {
  width: 32px;
}
.warning {
  margin-top: 24px;
  padding: 18px 48px 18px 30px;
  text-align: left;
  font-weight: 400;
  background-color: rgba(238, 239, 240, 1);
  font-size: 14px;
  gap: 15px;
}
.steps-container {
  gap: 24px;
}
.start-icon {
  width: 124px;
}
.start-icon-container {
  padding-top: 48px;
  padding-bottom: 24px;
}
.number {
  display: block;
  font-weight: 800;
  font-size: 48px;
  text-align: center;
  width: 90px;
  height: 90px;
  border: 1px solid;
  border-radius: 14px;
}

.message {
  margin-top: 30px;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 24px;
}

.metrics {
  gap: 20px;
}

.metrics-container {
  gap: 40px;
}

.metrics-name {
  font-size: 25px;
}

.opacity {
  opacity: 0.9;
}
.question {
  padding-top: 120px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.start-content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.steps {
  font-size: 20px;
  font-weight: 500;
}
.vital-sign {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.25px;
  padding-top: 44px;
}

@media (max-width: 2000px) {
  .description {
    padding: 24px;
    padding-bottom: 48px;
  }
  .question {
    padding-top: 30px;
  }
  img {
    width: 64px;
  }
  .number {
    font-size: 21px;
    height: 35px;
    width: 35px;
  }
  .vital-sign {
    font-size: 24px;
    padding-top: 24px;
  }
}
</style>
