<template>
  <div class="content">
    <locale-button />
    <div class="container-full" v-if="form">
      <!-- title -->
      <div class="row mb-4">
        <div class="title">PanopticAI - {{ t("page.settings") }}</div>
        <p id="app-version">{{ getAppVersion }}</p>
        <!-- <div > {{ getAppVersion }} </div> -->
      </div>

      <!-- settings -->
      <hr />
      <div>
        <!-- company setings -->
        <div class="row mb-3">
            <div class="fs-5 subheading"> {{ t('company_info.company_information')}} </div>
        </div>

        <!-- User Id and Profile Image -->
        <div class="row mb-6 flex-row-reverse" style="padding-top: 1%; padding-bottom: 1%;">
          <div class="col-2">
            <div style="float: right;display:grid;">
              <img id="profile-img" :src="this.companyForm.companyProfileImage">
              <button class="btn btn-primary btn-sm" onclick="document.getElementById('imgUploader').click()">Upload</button>
              <input type="file" ref="file" @change="handleImageUpload" accept="image/*" id="imgUploader" style="display:none">
            </div>
          </div>
        </div>
        <!-- companyId -->
        <div class="row my-3">
          <div class="col-6">
            {{ t('company_info.company_id')}}
          </div>
          <div class="col-6">
            <div class="col-5 text-end align-right">
              {{ companyForm.companyId }}
            </div>
          </div>
        </div>
        <!-- projectId -->
        <div class="row my-3">
          <div class="col-6">
            {{ t('company_info.project_id')}}
          </div>
          <div class="col-6">
            <div class="col-5 text-end align-right">
              {{ companyForm.projectId }}
            </div>
          </div>
        </div>

        <!-- SUBSCRIPTION PLAN -->
        <div class="row my-3">
          <!-- Plan Type -->
          <div class="col-6">
            {{ t("user_info.subscription_plan") }}
          </div>
          <div class="col-6">
            <div class="col-5 text-end align-right">
              {{
                userPlan
                  ? t('user_info.plan_type.'+userPlan.plan_type) 
                  : ''
              }}
              <button
                class="btn btn-primary btn-sm"
                v-if="userPlan && userPlan.plan_type == 'free'"
              >
                {{ t("button.upgrade") }}
              </button>
            </div>
            
          </div>
        </div>
        <!-- Validity -->
        <div class="row mb-3">
          <div class="col-6">
            {{ t("user_info.valid_until") }}
          </div>
          <div class="col-6">
            <div class="text-end align-right">
              {{
                userPlan 
                ? userPlan.expiry_date == 'unlimited' 
                  ? t('user_info.plan_type.unlimited') 
                  : userPlan.expiry_date
                : ''
              }}
            </div>
          </div>
        </div>
        <form v-on:submit="saveCompanySettings">
            <!-- Site Id -->
            <div class="row my-3">
                <div class="col-6">
                    <label for="siteIdInput" class="form-label">{{ t('company_info.site_id')}}</label>
                </div>
                <div class="col-6">
                    <input
                        class="form-control"
                        id="siteIdInput"
                        v-model="companyForm.siteId"
                    />
                </div>
            </div>
            <!-- Location -->
            <div class="row my-3">
                <div class="col-6">
                    <label for="locationInput" class="form-label">{{ t('company_info.location')}}</label>
                </div>
                <div class="col-6">
                    <input
                        class="form-control"
                        id="locationInput"
                        v-model="companyForm.location"
                    />
                </div>
            </div>
            <div class="row mx-1">
              <button type="submit" class="btn btn-primary">
                {{ t("button.save_settings") }}
              </button>
            </div>
        </form>
        <hr />

        <!-- CHANGE PASSWORD -->
        <div>
          <div class="row mb-3">
            <div class="fs-5 subheading">{{ t("password.password") }}</div>
          </div>
          <form v-on:submit="changePassword">
            <div class="row">
              <div class="col-6 mb-3">
                <label for="old_password" class="form-label">
                  {{ t("password.old") }}</label>
              </div>
              <div class="col-6 mb-3">
                <input
                  type="password"
                  id="old_password"
                  class="form-control"
                  v-model="password_change.old_password"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-3">
                <label for="new_password" class="form-label">
                  {{ t("password.new") }}</label>
              </div>
              <div class="col-6 mb-3">
                <input
                  type="password"
                  id="new_password"
                  class="form-control"
                  v-model="password_change.new_password"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 mb-3">
                <label for="confirm_password" class="form-label">
                  {{ t("password.confirm") }}</label>
              </div>
              <div class="col-6 mb-3">
                <input
                  type="password"
                  id="confirm_password"
                  class="form-control"
                  v-model="password_change.confirm_password"
                  @change="checkNewPassword"
                />
                <div
                  v-if="
                    password_change.confirm_password &&
                    !password_change.password_match
                  "
                  class="text-danger"
                >
                  {{ t("password.not_match") }}
                </div>
              </div>
            </div>
            <div class="row mb-4 mx-1">
              <button
                type="changePassword"
                class="btn btn-primary"
                :disabled="!password_change.password_match"
              >
                {{ t("button.change_password") }}
              </button>
            </div>
          </form>
          <hr />
        </div>
      </div>

      <!-- copyright message -->
      <div class="copyright text-center"><br />{{ t("copyright") }}<br /><br /></div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { newUserManager } from "../../user/company-user-manager.ts";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import LocaleButton from "../sidebar/locale-button.vue"
export default {
  data() {
    return {
      companyForm: undefined,
      form: undefined,
      password_change: {
        old_password: "",
        new_password: "",
        confirm_password: "",
        password_match: false,
      },
      userPlan: undefined,
    };
  },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const { profileFilled, updateProfileFilled } = inject('profileFilled')

    let currentUser = newUserManager.currentUser;
    if (!currentUser || !currentUser.cloudInfo) {
      router.push("/");
    }

    return { t, locale, profileFilled, updateProfileFilled };
  },
  components: {
    LocaleButton
  },
  watch: {
    "password_change.confirm_password"() {
      if (
        this.password_change.new_password !==
        this.password_change.confirm_password
      ) {
        this.password_change.password_match = false;
      } else {
        this.password_change.password_match = true;
      }
    },
    "password_change.new_password"() {
      if (
        this.password_change.new_password !==
        this.password_change.confirm_password
      ) {
        this.password_change.password_match = false;
      } else {
        this.password_change.password_match = true;
      }
    },
  },
  async mounted() {
    setTimeout(() => {
      // Retrieve user and sanity check on whether user exists
      let currentUser = newUserManager.currentUser; // this is the company client
      if (!currentUser) {
        return;
      }
      let customerUser = currentUser.currentUser; // This is the user under the company client

      // Determine company client's plan type
      let companySettings = currentUser.companySettings;
      this.companyForm = companySettings;
      this.userPlan = {
        user_id: companySettings.UID,
        plan_type: companySettings.planType,
        expiry_date: companySettings.planExpiryDate,
      }

      // Init form with user settings
      this.form = customerUser.userSettings;
      if (this.form) {
        this.form.userId = customerUser.userId;
      }
    }, 500);
  },
  methods: {
    async saveCompanySettings(e) {
        if (e) { e.preventDefault(); }
        let currentUser = newUserManager.currentUser;
        if (!currentUser) {
            return;
        } else {
            const response = await currentUser.setCompanySettings(this.companyForm);
            if (response) {
                alert(this.t("message.settings_updated"));
            }
        }
    },
    async saveSettings(e) {
      if (e) { e.preventDefault(); }
      let currentUser = newUserManager.currentUser.currentUser;
      if (!currentUser) {
        return;
      } else {
        const response = await currentUser.setUserSettings(this.form);
        this.updateProfileFilled(true)
        if (response) {
          alert(this.t("message.settings_updated"));
        }
      }
    },
    async changePassword(e) {
      e.preventDefault();

      let currentUser = newUserManager.currentUser;
      if (!currentUser) {
        return;
      } else {
        const response = await currentUser.changePassword(this.password_change.old_password, this.password_change.new_password)
        if (response) {
          alert(this.t("message.password_updated"));
        } else {
          alert(this.t("message.password_update_failed"));
        }
      }
    },
    handleImageUpload() {
      let fr = new FileReader();
      let file = (this.$refs.file && this.$refs.file.files[0]) ? this.$refs.file.files[0] : undefined;
      
      var myImg = undefined;
      if (file) {

        fr.onload = function() {
          let img = new Image();
          img.onload = function() {
            let canvas = document.createElement("canvas");
            canvas.width = 80;
            canvas.height = 80;
            canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height)
            myImg = canvas.toDataURL();
          }
          img.src = fr.result
        }

        fr.readAsDataURL(file);
      }

      let count = 5;
      const uploadTimer = setInterval(() => {
        if (myImg) {
          this.companyForm.companyProfileImage = myImg;
          this.saveCompanySettings(undefined);
          clearInterval(uploadTimer);
        } 
        if (count < 0) {
          alert(`Failed to upload, please try again`, )
          clearInterval(uploadTimer)
        }
        count -= 1;
      }, 100);
    }
  },
  computed: {
    getAppVersion() {
      return this.$root.$data.appTitle
    }, 
    formUserId() {
      if (this.companyForm && this.companyForm.UID) { return `${this.t('user_info.user_id')}: ${this.companyForm.UID}`; }
      else { return "" }
    }
  }
};
</script>

<style scoped>
.content {
  position: static;
  right: 0px;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.subheading {
  font-weight: bold;
}
.container-full {
  padding: 20px;
}

#app-version {
  color: #888888;
  font-style: italic;
  font-weight: normal;
}

#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  min-width:80px;
  max-width:80px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.align-left {
  position: relative;
  float: left;
}
.align-right {
  position: relative;
  float: right;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 0;
}
@media (max-width: 768px) {
  .content {
    padding-left: 0px;
    margin-bottom: 87px;
  }
}
</style>
