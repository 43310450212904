<template>
  <div class="start-content-container">
    <div class="start-content" id="start-content">
      <div class="header m-0 bg-fwd-orange fwd-dark-green align-items-center">
        <img src="./assets/face-id.svg" alt="" class="scanning-icon">
        <p v-if="startCountdown" class="message" v-html="t('message.final_check', { second: countdown })"></p>
      </div>
      <div class="description fwd-dark-green">
        <conditions-hud v-if="conditions" :conditions="conditions" />
      </div>
    </div>
  </div>
</template>

<script>
import faceAnimation from "./assets/face-animation.json";
import ConditionsHud from "./conditions-hud.vue";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    countdown: Number,
    conditions: Object,
    startCountdown: Boolean
  },
  components: {
    ConditionsHud,
  },
  data() {
    return {
      faceAnimation,
    };
  },
};
</script>

<style scoped>
.message {
  font-style: normal;
  text-align: center;
  font-size: 25px;
  margin: 0;
}
.subtitle {
  font-weight: 500;
  font-size: 36px;
  text-align: center;
  color: white;
}
.title {
  font-weight: 600;
  font-size: 56px;
  text-align: center;
  color: white;
}
.vital-sign {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0.25px;
  color: white;
  padding-top: 44px;
}
.scanning-icon {
  width: 160px;
  height: 160px;
  ;
}
@media (max-width: 2000px) {
  .question {
    padding-top: 30px;
  }
  img {
    width: 64px;
  }
  .number {
    font-size: 24px;
    line-height: 45px;
    height: 45px;
    width: 45px;
  }
  .subtitle {
    font-size: 16px;
  }
  .title {
    font-size: 32px;
  }
  .vital-sign {
    font-size: 24px;
    padding-top: 24px;
  }
}

@media (max-width: 940px) {
  .start-content {
    top: 30%;
  }
}

@media screen and (width: 2160px) and (height: 3180) {
  .start-content {
    top: 25%;
    left: 12.5%;
    width: 75%;
  }
}
</style>