<template>
  <div>
    <div v-if="proAccount">
      <div class="header w-100 m-0 bg-fwd-orange fwd-dark-green text-break">
        <p class="title m-0">
          {{ t("scan_result.title") }}
        </p>
        <div class="container p-0">
          <div class="row metrics">
            <div
              class="text-center col align-items-center metrics p-0"
              v-for="(metric, index) in $tm('scan_result.metrics')"
              :key="index"
            >
              <img :src="require('../assets/' + metric.image)" alt="" class="result-logo" />
            </div>
          </div>
        </div>
        <div class="container p-0">
          <div class="row metrics">
            <div
              class="text-center col align-items-center metrics"
              v-for="(metric, index) in $tm('scan_result.metrics')"
              :key="index"
            >
              <p class="metrics-name" v-html="metric.name"></p>
            </div>
          </div>
        </div>
        <div class="container p-0">
          <div class="row metrics">
            <div
              class="text-center col align-items-center metrics"
              v-for="(metric, index) in $tm('scan_result.metrics')"
              :key="index"
            >
              <div>
                <p class="vital-sign-value">{{ getData(metric.tag) }}</p>
                <p v-if="metric.tag == 'bloodPressure'" class="unit">mmHg</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="header error w-100 m-0">
        <p class="title fs-2 m-0">
          Your pro account subscription is expired <br />
          Please contact PanopticAI
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn continue-button" @click="newScan">
          {{ t("button.start_again") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../../user/company-user-manager";
export default {
  setup() {
    const { t, tm } = useI18n();
    return {
      t,
      tm,
    };
  },
  emits: ["removeProfile"],
  props: {
    vitalSign: Object,
    showQr: Boolean,
  },
  computed: {
    proAccount() {
      if (newUserManager.currentUser.companySettings.planType == "pro") {
        if (
          newUserManager.currentUser.companySettings.planExpiryDate !== "unlimited" &&
          new Date(newUserManager.currentUser.companySettings.planExpiryDate) < new Date()
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    getData(tag) {
      if (tag == "stress") {
        return this.stressValue();
      } else if (tag == "bloodPressure") {
        return this.vitalSign[tag];
      } else {
        if (!this.vitalSign || !this.vitalSign[tag]) {
          return "-";
        }
        if (this.vitalSign[tag]) {
          return this.vitalSign[tag].toFixed(0) + (tag == "generalWellness" ? "%" : "");
        } else {
          return "-";
        }
      }
    },
    stressValue() {
      if (!this.vitalSign || !this.vitalSign.stress) {
        return "-";
      }
      if (this.vitalSign.stress < 2) {
        return this.t("health_report.low");
      } else if (this.vitalSign.stress >= 2 && this.vitalSign.stress < 3) {
        return this.t("health_report.normal");
      } else {
        return this.t("health_report.high");
      }
    },
    newScan() {
      this.$emit("removeProfile");
    },
  },
  data() {
    return {
      disabled: true,
    };
  },
};
</script>

<style scoped>
.row {
  padding: 0;
  margin: 0;
}
.col {
  padding: 0;
  margin: 0;
}
.continue-button {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  border-radius: 240px;

  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.231689px;
  height: 240px;
  width: 480px;
  position: relative;
  top: -120px;
}
.number {
  display: inline-block;

  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.25px;
  width: 90px;
  height: 90px;
  border: 1px solid #333d47;
  border-radius: 56px;
  margin: 0 40px 15px 40px;
}
p {
  padding: 0;
  margin: 0;
}
.question {
  padding-top: 60px;

  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.steps {
  font-size: 18px;
  font-weight: 500;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 68px;
  text-align: center;
  letter-spacing: 0.25px;
}
.vital-sign {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.25px;
  padding: 24px 0;
}
.vital-sign-value {
  font-style: normal;
  font-weight: 600;
  font-size: 108px;
  line-height: 100px;
  letter-spacing: 0.25px;
  text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
}
.result-logo {
  width: 50px;
  height: 50px;
}
@media (max-width: 2000px) {
  .continue-button {
    font-size: 24px;
    height: 90px;
    width: 200px;
    top: -45px;
  }
  .number {
    font-size: 24px;
    line-height: 45px;
    height: 45px;
    width: 45px;
  }
  .question {
    padding-top: 40px;
    gap: 10px;
  }
  .steps {
    font-size: 16px;
  }
  .title {
    font-size: 32px;
  }
  .vital-sign {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
  }
  .vital-sign-value {
    font-size: 31px;
    line-height: 43px;
  }
}
</style>