import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from "vue";
import Main from "./main.vue";
import HomePage from "./views/home/home-page.vue";
// import ProfilePage from "./views/profile-page/profile-page.vue";
// import SubscriptionPage from "./views/subscription/subscription-page.vue";
// import Settings from "./views/settings/settings.vue";
import Settings from "./views/settings/company-settings.vue";
import DummyPage from "./views/dummy-page/dummy-page.vue";
import Login from "./views/login/login.vue";
import AWSLogin from "./views/login/aws-login.vue";
// import ProfileInput from "./views/login/profile-input.vue"
import ProfileInput from "./views/login/company-profile-input.vue";
import { createI18n } from "vue-i18n";
import locales from "./locales.js";
import { createRouter, createWebHashHistory } from "vue-router";
import { useFirebase } from "./firebase.js";
const dragscrollNext = require("vue-dragscroll");
import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";
// import { dragscrollNext } from "vue-dragscroll";
import './font/font.scss'

import { newUserManager } from "./user/user-manager";

/* AWS config */
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

import { vitalsConfig, loginOption, applicationOption } from "./vitals-config";

let LoginModule;
switch (vitalsConfig.login) {
  case loginOption.firebase:
    LoginModule = require("./views/login/login.vue").default;
    useFirebase();
    break;
  case loginOption.aws:
    LoginModule = require("./views/login/aws-login.vue").default;
    break;
  default:
    LoginModule = require("./views/login/aws-login.vue").default;
    break;
}

let basicRoutes = [
  { path: "/", component: LoginModule },
  { path: "/home", component: HomePage },
  { path: "/setting", component: Settings },
  { path: "/input-profile", component: ProfileInput },
];

let additionalRoutes: any[] = [];
switch (vitalsConfig.application) {
  case applicationOption.b2c:
    additionalRoutes = [
      // { path: "/dashboard", component: Dashboard },
      // { path: "/multi-user", component: MultiUserPage },
      // { path: "/event", component: DummyPage },
      // { path: "/chat", component: DummyPage },
    ];
    break;
  case applicationOption.b2b:
    additionalRoutes = [
      // { path: "/email", component: EmailPage },
    ];
    break;
  default:
    additionalRoutes = [];
    break;
}
additionalRoutes.forEach((element) => {
  basicRoutes.push(element);
});
const routes = basicRoutes;

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const i18n = createI18n({
  legacy: false,
  locale: "中", // set locale
  messages: locales, // set locale messages
  warnHtmlInMessage: "off",
});

const app = createApp(Main);
app.use(i18n);
app.use(Vue3Lottie, { name: "Vue3Lottie" });
app.use(router);
app.directive("dragscroll", dragscrollNext);
app.mount("#app");
