<template>
  <div>
    <div class="start-content-container justify-content-center">
      <div class="start-content">
          <metrics-result :vitalSign="vitalSign" @removeProfile="removeProfile" />
          <print-guideline @removeProfile="removeProfile" />
      </div>
    </div>
  </div>
</template>

<script>
import PrintGuideline from "./print-guideline.vue";
import MetricsResult from "./metrics-result.vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { locale } = useI18n();
    return {
      locale,
    };
  },
  props: {
    vitalSign: Object,
    userProfile: Object,
  },
  components: {
    MetricsResult,
    PrintGuideline,
  },
  emits: ["removeProfile"],
  methods: {
    getQRSize() {
      const width = window.innerWidth;
      if (width < 2000) {
        this.qrSize = 150;
      } else {
        this.qrSize = 240;
      }
    },
    removeProfile() {
      this.$emit("removeProfile");
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.getQRSize);
  },
  mounted() {
    this.getQRSize();
    window.addEventListener("resize", this.getQRSize);
    // this.generateQrLink();
  },
};
</script>