<template>
  <div>
    <div id="non-printable">
      <LoadingScreen
        v-if="
          !Object.keys(this.$root.$data.doneLoading).every((key) => {
            return this.$root.$data.doneLoading[key];
          })
        "
      ></LoadingScreen>
      <!-- button navigation -->
      <div class="locale-button">
        <locale-button :home="true" />
      </div>

      <face-detection-view
        :enable="starting"
        :vsStatus="vsStatus"
        @finishMeasurement="finishMeasure"
        @updateTimeLeft="updateTimeLeft"
        @detectionResult="updateDetectionResult"
        @scanParameters="updateScanParameters"
        :device="devices.length > 1 ? devices[cameraIndex].id : ''"
        @updateCountdown="updateCountdown"
        @updateStartCountdown="updateStartCountdown"
        :countdown="countdown"
        @updateConditions="updateConditions"
        :failedScan="failedScan"
      />
      <div class="position-relative">
        <!-- popup user profile -->
        <user-profile-input
          v-if="form && showModal"
          :showModal="showModal"
          @updateProfile="updateProfile"
          :profileFilled="profileFilled"
          :form="form"
          @updateModal="updateModal"
        />

        <!-- video container -->
        <div id="video-container" ref="videoContainer">
          <!-- condition checking & instructions -->
          <div></div>
          <!-- 
      <img id="lify-logo" src="./assets/lify.svg" />
      <img id="panoptic-logo" src="./assets/panoptic_ai.svg" />
      <img id="fittery-logo" src="./assets/fittery.png" alt="">
      <img id="tda-logo" src="./assets/tda-logo.svg" alt=""> -->

          <div v-if="failedScan" class="instruction-text">
            <p class="w-75">
              Face Lost!
              <br />
              Please keep your face visible to the camera
            </p>
          </div>
        </div>
        <start-content
          :vsStatus="vsStatus"
          :countdown="countdown"
          :conditions="conditions"
          :enable="detectionResult"
          :showTC="showTC"
          @startMeasurement="startMeasurement"
          @removeProfile="removeProfile"
          @updateModal="updateModal"
          @updateShowTC="updateShowTC"
          v-if="vsStatus !== 'measuring' && !showModal && !showResult && !scanFinished"
        />
        <condition-checking
          :conditions="conditions"
          :countdown="countdown"
          :startCountdown="startCountdown"
          v-if="vsStatus == 'measuring' && starting && getTimeLeft == null && !showModal"
        />
        <img
          v-if="devices && devices.length > 1 && vsStatus !== 'measuring' && starting && !showModal"
          :disabled="vitalSign !== undefined"
          class="btn camera-switch"
          @click="switchCamera"
          src="./assets/switch-camera.svg"
        />
        <result v-if="scanFinished" :vitalSign="vitalSign" :userProfile="form" @removeProfile="removeProfile" />
        <circular-progress :timeLeft="getTimeLeft" :radius="radius" :stroke="14.5" v-if="vsStatus == 'measuring'" />
      </div>
    </div>
    <div id="printable" v-if="vitalSign && form">
      <report :userProfile="form" :vitalSign="vitalSign" :style="{ scale: 1.62, transformOrigin: 'top left' }" />
    </div>
  </div>
</template>

<script>
import Logger from "../../common/logger.js";
import { ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import api from "../../common/api.js";
import adapter from "webrtc-adapter";
import LoadingScreen from "./loading-screen.vue";
import FaceDetectionView from "./face-detection-view.vue";
import userProfileInput from "./user-profile-input.vue";
import { newUserManager } from "../../user/company-user-manager.ts";
import Result from "./result/result.vue";
import StartContent from "./start-content.vue";
import LocaleButton from "../sidebar/locale-button.vue";
import ConditionChecking from "./condition-checking.vue";
import CircularProgress from "./circular-progress.vue";
import { resetMovementArray } from "@/plugin/plugin_helpers.js";
import Report from "./result/report.vue";

export default {
  name: "App",
  props: {
    simulation: Boolean,
  },
  setup() {
    const { idle, updateIdle } = inject("idle");
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser ? newUserManager.currentUser.currentUser : null;
    let vitalSign = null;
    let scanParameters = null;
    if (newUserManager.currentUser && newUserManager.currentUser.cloudInfo) {
      vitalSign = currentUser.lastVitalSigns;
      scanParameters = currentUser.lastHealth ? currentUser.lastHealth.scanParameters : null;
    } else {
      router.push("/");
    }

    return {
      t,
      locale,
      router,
      starting: ref(idle),
      stopping: ref(true),
      sysStatus: ref("standby"),
      vsStatus: ref("idle"),
      flask_fps: ref(),
      vitalSign: ref(vitalSign),
      scanParameters: ref(scanParameters),
      conditions: ref(),
      message: ref(),
      disclaimer: ref(),
      pythonBackend: ref(false),
      timeLeft: ref(null),
      user: ref(currentUser),
      idle,
      updateIdle,
      showTutorial: ref(false),
      profileFilled,
      updateProfileFilled,
    };
  },
  mounted() {
    // console.log(newUserManager.currentUser)
    // console.log(newUserManager.currentUser.currentUser)
    // console.log(newUserManager.currentUser.currentUser.getUserSettings())
    // load user / customer form

    setTimeout(async () => {
      let currentUser = newUserManager.currentUser.currentUser;
      if (currentUser) {
        this.form = await currentUser.getUserSettings();
      }
    }, 50);
  },
  async created() {
    await this.getUserType();
    if (window.sessionStorage.userSettings) {
      this.form = JSON.parse(window.sessionStorage.userSettings);
    }
    if (this.$root.$data.debugMode) {
      let currentUser = newUserManager.currentUser ? newUserManager.currentUser.currentUser : null;
      if (currentUser && !currentUser.lastHealth) {
        if (!currentUser.email) {
          currentUser._genUserInfo();
          Logger.log("%c<email-page debug>generated user info", "color:magenta");
          this.form = currentUser.userSettings;
        }
        if (!currentUser.lastHealth) {
          currentUser._genLastHealth();
          Logger.log("%c<email-page debug>generated last health", "color:magenta");
          this.vitalSign = currentUser.lastVitalSigns;
        }
        Logger.log("%cgenerated currentUser: ", "color:magenta", currentUser);
      }
    }
  },
  watch: {
    failedScan(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.failedScan = false;
        }, 5000);
      }
    },
    vsStatus(newValue) {
      if (newValue == "measuring") {
        this.conditions = undefined;
        this.message = undefined;
      }
    },
    devices() {
      if (this.devices[0].text !== "") {
        clearInterval(this.checkDevice);
      }
    },
    sysStatus(status) {
      Logger.info(`Status changed from "${this.sysStatus}" to "${status}".`);
      if (status == "running") {
        this.starting = false;
        this.message = undefined;
      }

      if (status == "standby") {
        this.stopping = false;
        this.message = undefined;
      }
    },
    healthObject: {
      deep: true,
      handler: function () {
        this.vitalSign = newUserManager.currentUser.currentUser.lastVitalSigns;
      },
    },
    async starting(val) {
      if (val) {
        this.checkDevice = setInterval(async () => {
          let devices = await navigator.mediaDevices.enumerateDevices();
          devices = devices.filter((device) => device.kind === "videoinput");
          this.devices = devices.map((device, index) => {
            return { text: device.label, id: device.deviceId, deviceIndex: index };
          });
        }, 1000);
      } else if (!val) {
        this.stopSystem();
      }
    },
  },
  data() {
    return {
      detectionResult: undefined,
      aspectRatio: undefined,
      form: undefined,
      userType: undefined,
      result: undefined,
      scanFinished: false,
      number: 0,
      cameraIndex: 0,
      devices: [{ text: "", id: "", deviceIndex: 0 }],
      checkDevice: undefined,
      countdown: 3,
      showResult: false,
      showModal: false,
      scanResultId: "",
      showTC: false,
      failedScan: false,
      resetTimeout: undefined,
      startCountdown: false,
    };
  },
  methods: {
    updateStartCountdown(value) {
      this.startCountdown = value;
    },
    updateShowResult(value) {
      this.showResult = value;
    },
    updateShowTC(value) {
      this.showTC = value;
    },
    updateModal(value) {
      this.showModal = value;
    },
    updateConditions(conditions) {
      this.conditions = conditions;
    },
    updateCountdown(countdown) {
      this.countdown = countdown;
    },
    switchCamera() {
      this.cameraIndex = (this.cameraIndex + 1) % this.devices.length;
    },
    async getUserType() {
      setTimeout(() => {
        if (!this.currentUser) return;
        else {
          this.userType = this.currentUser.userSettings.planType;
        }
      }, 2000);
    },
    showGuide() {
      this.showTutorial = !this.showTutorial;
    },
    startMeasurement() {
      // check conditions
      this.starting = true;
      this.vitalSign = null;
      this.scanParameters = null;
      if (newUserManager.currentUser) {
        let currentUser = newUserManager.currentUser.currentUser;
        if (currentUser) {
          currentUser.clearHealth();
        }
      }
      this.showTC = false;
      this.vsStatus = "measuring";
      this.failedScan = false;
    },
    updateTimeLeft(newValue) {
      this.timeLeft = newValue;
      // console.log("home-page time left: ", this.timeLeft);
    },
    finishMeasure(failedScan) {
      this.vsStatus = "idle";
      this.failedScan = failedScan;
      resetMovementArray();
      this.starting = false;
      if (!this.failedScan) {
        this.showResult = true;
        this.scanFinished = true;
        this.resetTimeout = setTimeout(() => {
          this.removeProfile();
        }, 5 * 1000 * 60);
      }
    },
    updateDetectionResult(newValue) {
      this.detectionResult = newValue;
    },
    updateScanParameters(newValue) {
      this.scanParameters = newValue;
    },
    getAspectRatio(ar) {
      this.aspectRatio = ar;
    },
    onResult(result) {
      this.detectionResult = result;
    },
    refreshStatus() {
      api.refreshStatus().then((data) => {
        this.flask_fps = data.fps;

        if (data.status == "running") {
          this.starting = false;
        }

        if (data.status == "standby") {
          this.stopping = false;
        }

        if (this.sysStatus !== data.status) {
          Logger.info(`Status changed from "${this.sysStatus}" to "${data.status}".`);
        }

        this.sysStatus = data.status;
      });
    },
    startSystem: function () {
      // Logger.info("Start button pressed");

      // this.enableSound();

      this.updateIdle();
      this.starting = true;
      this.stopping = false;
      this.conditions = undefined;
      this.vitalSign = undefined;

      if (this.pythonBackend) {
        api
          .startSystem()
          .then(() => {
            this.refreshStatus();
          })
          .catch((err) => {
            console.error(err);
            alert(err.message);
            this.starting = false;
          });
        return false;
      } else {
        //this.sysStatus = "running";
        return false;
      }
    },
    stopSystem: function () {
      // Logger.info("Stop button pressed.");

      this.updateIdle();
      this.stopping = true;
      this.starting = false;
      this.vitalSign = undefined;
      this.conditions = undefined;
      this.detectionResult = undefined;
      this.vsStatus = "idle";
      this.failedScan = false;
      this.showResult = false;
      resetMovementArray();

      if (this.pythonBackend) {
        api.stopSystem().then(() => {
          this.starting = false;
          this.stopping = true;
          this.refreshStatus();
        });
        return false;
      } else {
        this.sysStatus = "standby";
        return false;
      }
    },
    fullScreen() {
      this.$refs.videoContainer.requestFullscreen();
    },
    async removeProfile() {
      await newUserManager.currentUser.currentUser.clearUserSettings();
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(false);
      this.vitalSign = null;
      this.scanParameters = null;
      this.number = 0;
      this.scanFinished = false;
      this.showTC = false;
      this.failedScan = false;
      if (this.resetTimeout) {
        clearTimeout(this.resetTimeout);
        this.resetTimeout = undefined;
      }
    },
    async updateProfile(form) {
      await newUserManager.currentUser.currentUser.setUserSettings(form);
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(true);
      this.showTC = true;
    },
  },
  computed: {
    getTimeLeft() {
      return this.timeLeft;
    },
    on() {
      return '"' + this.t("button.on") + '"';
    },
    healthObject() {
      if (this.$root.$data.healthObject !== undefined) {
        return "healthObject updated.";
      } else {
        return "no health result";
      }
    },
    radius() {
      return 72.5;
    },
    gap() {
      if (adapter.browserDetails.browser == "safari") {
        return "20px";
      } else {
        return 0;
      }
    },
  },
  components: {
    FaceDetectionView,
    userProfileInput,
    Result,
    StartContent,
    LocaleButton,
    ConditionChecking,
    CircularProgress,
    Report,
    LoadingScreen,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap");

html {
  scroll-behavior: smooth;
}
body {
  background: url("./assets/background-image.svg");
  max-height: calc(98vh - 56px) !important;
  min-height: calc(98vh - 56px) !important;
  width: 100vw !important;
  /* overflow: hidden; */
}

@media print {
  body {
    background: white;
    max-height: 70vh !important;
    min-height: 70vh !important;
  }
}
</style>

<style scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.black {
  color: black;
}
.camera-switch {
  transform: scale(0.8);
  position: absolute;
  bottom: 5%;
  right: 3%;
}
.copyright {
  position: relative;
  font-size: 14px;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}
/* Hide scrollbar for IE, Edge and Firefox */
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}
.disclaimer {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 20px;
  padding: 10px;
  color: black;
  background-color: lightgrey;
  opacity: 0.8;
  font-size: 0.9em;
  text-align: center;
}
#fittery-logo {
  position: absolute;
  width: 135px;
  height: 135px;
  left: 530px;
  top: 80px;
}
.huds-container {
  position: absolute;
  top: 208px;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
  overscroll-behavior: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
#icon-mobile {
  display: none;
}
.info-bar {
  position: absolute;
  bottom: 5%;
  left: calc(50% - 64px);
}
.info-button {
  width: 150px;
  height: 75px;
  min-height: 50px;
  font-size: large;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  margin-bottom: auto;
}

.instruction-box {
  position: absolute;
  top: 10%;
  width: calc(100% - 87px);
  font-size: 48px;
  text-align: center;
  color: #ff3535;
  text-shadow: 1px 1px white;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.instruction-text {
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 87px);
  top: 12%;
  font-size: 30px;
  color: white;
  text-shadow: 0px 0px 5px black;
  z-index: 3;
  text-align: center;
  pointer-events: none;
}
#lify-logo {
  position: absolute;
  width: 135px;
  top: 80px;
  left: 80px;
}
#panoptic-logo {
  position: absolute;
  width: 280px;
  left: 230px;
  top: 126px;
}
#printable {
  display: none;
}
.running-message {
  margin-top: 20%;
  font-size: 48px;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 5px #000000;
}
.system-message {
  margin-top: 20%;
}
#tda-logo {
  position: absolute;
  left: 698px;
  top: 117px;
}
.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
#video-container {
  overflow: hidden;
  z-index: 0;
  height: calc(96vh - 56px);
}
.vitals-row {
  margin-left: 3rem;
  margin-right: 3rem;
}
.vital-sign-hud {
  margin-left: 48px;
  margin-right: 48px;
}
.vsButton {
  display: flex;
  justify-content: center;
}
.white {
  color: white;
}

@media print {
  #non-printable {
    display: none;
  }
  #printable {
    display: block;
  }
}

@media (max-width: 2000px) {
  #fittery-logo {
    width: 100px;
    height: 100px;
    left: 365px;
    top: 40px;
  }
  .huds-container {
    top: 500px;
    height: 45%;
  }
  #lify-logo {
    width: 100px;
    top: 40px;
    left: 38px;
  }
  #panoptic-logo {
    width: 200px;
    left: 150px;
    top: 75px;
  }
  #tda-logo {
    width: 140px;
    left: 480px;
    top: 65px;
  }
}

@media (max-width: 940px) {
  #fittery-logo {
    width: 70px;
    height: 70px;
    left: 305px;
    top: 30px;
  }
  #lify-logo {
    width: 70px;
    top: 30px;
    left: 38px;
  }
  #panoptic-logo {
    width: 175px;
    left: 120px;
    top: 55px;
  }
  #tda-logo {
    width: 110px;
    left: 385px;
    top: 47px;
  }
}

@media (max-width: 720px) {
  .huds-container {
    top: 350px;
  }
  .info-bar {
    left: calc(50% - 48px);
  }
  .vitals-row {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
