<template>
  <div class="fwd-header">
    <div class="d-flex flex-row">
      <img src="../home/assets/fwd-icon.svg" alt="" />
      <div v-if="!home" class="d-flex flex-row navigation-buttons">
        <div @click="goToHome" class="cursor-pointer">Home</div>
        <div class="cursor-pointer" id="logout-button" data-bs-toggle="modal" data-bs-target="#confirmLogoutModal">Logout</div>
      </div>
    </div>
    <div class="locale-changer normal d-flex align-items-center">
      <button
        v-for="(locale, index) in $i18n.availableLocales"
        :key="index"
        :class="`option ${$i18n.locale == locale ? 'fwd-orange unclickable' : ''} ${index ? 'border-left' : ''}`"
        @click="$i18n.locale = locale"
      >
        {{ locale }}
      </button>
      <!-- <select v-model="$i18n.locale" id="locale-selector" width="50px">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
    </select> -->
    </div>
  </div>
  <div
    class="modal fade"
    id="confirmLogoutModal"
    tabindex="-1"
    aria-labelledby="confirmLogoutModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmLogoutModalLabel">{{ t("page.logout") }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ t("logout_prompt") }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ t("option.logout_no") }}
          </button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="logout">
            {{ t("option.logout_yes") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { newUserManager } from "../../user/company-user-manager.ts";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useRouter } from "vue-router";
export default {
  name: "locale-button",
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    return { t, locale, router };
  },
  props: {
    home: Boolean,
  },
  methods: {
    goToHome() {
      this.router.push("/home")
    },
    async logout() {
      await newUserManager.logout();
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.border-left {
  border-left: 1px solid #859d99 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.fwd-header {
  height: 56px;
  background: white;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  z-index: 100;
}
#logout-button {
  pointer-events: stroke;
}
.navigation-buttons {
  gap: 20px;
  font-size: 16px;
  margin-left: 32px;
}
.option {
  font-size: 14px;
  font-weight: 700;
  color: #ced8d6;
  background-color: transparent;
  border: none;
  padding: 0 8px;
  line-height: 14px;
}
.unclickable {
  pointer-events: none;
}
</style>
