<template>
  <DebugConsole v-if="debugMode" />
  <router-view />
</template>

<script>
import { ref, provide } from "vue";
import DebugConsole from "./common/debug-console.vue";

export default {
  name: "main-app",
  setup() {
    const idle = ref(false);
    const filled = false;
    const profileFilled = ref(filled);
    function updateIdle() {
      idle.value = !idle.value;
    }
    function updateProfileFilled(value) {
      profileFilled.value = value;
    }
    provide("profileFilled", { profileFilled, updateProfileFilled });
    provide("idle", { idle, updateIdle });

    return {
      idle,
      currentUser: undefined,
    };
  },
  data() {
    let debugMode = false;
    let appVersion = "0.0.3";
    let appTitle = `FWD x PanopticAI ${appVersion}`;

    return {
      vitalSignObject: undefined,
      healthObject: undefined,
      host: location.host,
      appVersion: appVersion,
      appTitle: appTitle,
      debugMode: debugMode,
      debugMessage: "[ Your debug message ]",
      doneLoading: {
        faceDetectionView: false,
        system: true,
        scanning: true,
      },
    };
  },
  mounted() {
    if (this.debugMode) {
      console.log(`%c< ${this.appTitle} > DEBUG MODE`, "color: red");
    } else {
      console.log(`< ${this.appTitle} >`);
    }
  },
  computed: {
    noPassword() {
      return this.host.match(/^localhost:[\d]{1,4}/) || location.href.match(/^.*\/#\/report.*/);
    },
  },
  components: {
    DebugConsole,
  },
};
</script>

<style>
#app {
  font-family: FWDCircular !important;
}

.border-fwd-dark-greem {
  border-color: #183028 !important;
}

.border-fwd-orange {
  border-color: #e87722 !important;
}

.bg-fwd-orange {
  background-color: #e87722;
}

.bg-fwd-orange-50 {
  background-color: #f3bb90;
}

.bg-fwd-orange-20 {
  background-color: #fae4d3;
}

.continue-button {
  border-radius: 4px;
  background: #e87722;
  font-size: 20px;
  width: 229px;
  color: white;
  border: none;
}

.continue-button-clear {
  border-radius: 4px;
  border: 2px solid #e87722;
  background-color: transparent;
  font-size: 20px;
  width: 229px;
  color: white;
}

.continue-button:disabled {
  opacity: 0.7;
  background-color: #e87722;
  color: white;
  border: none;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}
.description {
  padding: 80px 123px 115px 123px;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  border-radius: 0px 0px 36px 36px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
}
.fwd-orange {
  color: #e87722 !important;
}

.fwd-dark-green {
  color: #183028;
}

.g-18 {
  gap: 18px;
}

.g-24 {
  gap: 24px;
}

.header {
  border-radius: 24px 24px 0px 0px;
  padding: 120px 80px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.metrics {
  gap: 20px;
}

.metrics-container {
  gap: 40px;
}
.metrics-image {
  width: 50px;
  height: 50px;
}

.metrics-name {
  font-size: 25px;
}
::-webkit-scrollbar {
  display: none;
}
.start-content {
  border-radius: 24px;
  width: 100%;
  overflow: scroll;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.start-content-container {
  position: absolute;
  top: 0%;
  height: calc(98vh - 56px);
  padding: 56px 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.subtitle {
  font-weight: 500;
  font-size: 36px;
  text-align: center;
}
.title {
  font-weight: 600;
  font-size: 56px;
  text-align: center;
}
@media (max-width: 940px) {
  .start-content {
    top: 120px;
  }
}

@media (max-width: 2000px) {
  .continue-button {
    padding: 10px;
  }
  .description {
    font-size: 20px;
    padding: 24px;
    padding-bottom: 48px;
  }
  .header {
    padding: 24px;
  }
  .subtitle {
    font-size: 25px;
  }
  .title {
    font-size: 31px;
  }
}
@media screen and (width: 2160px) and (height: 3180) {
  .start-content {
    top: 25%;
    left: 12.5%;
    width: 75%;
  }
}
</style>