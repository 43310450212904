<template>
  <div class="content">
    <div id="locale">
      <locale-button :home="true" />
    </div>
    <div class="container position-relative">
      <!-- title -->
      <div class="mt-4">
        <div class="title">PanopticAI</div>
        <p id="app-version">{{ getAppVersion }}</p>
      </div>

      <!-- settings -->
      <div class="loadingMessage" v-if="!settingsLoaded">{{ t("message.loading") }} ...</div>
      <div v-if="settingsLoaded">
        <div class="loadingMessage" style="text-decoration: underline">{{ t("message.fill_profile") }}</div>

        <!-- User Id and Profile Image -->
        <div class="row mb-6" style="padding-top: 1%; padding-bottom: 1%">
          <div class="col-9">
            <div class="fs-10">{{ formUserId }}</div>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <div style="float: right; display: grid">
              <!-- <img id="profile-img" :src="this.form.profileImage"> -->
              <!-- <button class="btn btn-primary btn-sm" onclick="document.getElementById('imgUploader').click()">Upload</button> -->
              <!-- <input type="file" ref="file" @change="handleImageUpload" accept="image/*" id="imgUploader" style="display:none"> -->
            </div>
          </div>
        </div>

        <!-- Personal Information -->
        <hr />
        <div class="row mb-3">
          <div class="fs-5 subheading">{{ t("company_info.company_information") }}</div>
        </div>
        <form v-on:submit="saveSettings">
          <!-- Gender -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="siteID" class="form-label">
                {{ t("company_info.site_id") }}
              </label>
            </div>
            <div class="col-6">
              <input type="text" class="form-control" id="siteID" v-model="this.form.siteId" @input="checkDisabled" />
            </div>
          </div>
          <!-- Gender -->
          <div class="row mb-3">
            <div class="col-6">
              <label for="location" class="form-label">
                {{ t("company_info.location") }}
              </label>
            </div>
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                id="location"
                v-model="this.form.location"
                @input="checkDisabled"
              />
            </div>
          </div>

          <!-- button -->
          <hr />
          <div class="row mx-1">
            <button type="submit" class="btn btn-primary" :disabled="disabled">
              {{ t("button.continue") }}
            </button>
          </div>
        </form>
      </div>

      <!-- copyright message -->
      <div class="copyright text-center"><br /><br />{{ t("copyright") }}</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { newUserManager } from "../../user/company-user-manager";
import localeButton from "../sidebar/locale-button.vue";
import { generateEmptyCompanySettings } from "@/user/user_utilities";

export default {
  setup() {
    const { t, locale } = useI18n();

    if (!newUserManager.currentUser) {
      const router = useRouter();
      router.push("/");
    }

    return { t, locale };
  },
  components: {
    localeButton,
  },
  data() {
    return {
      form: undefined,
      // form: generateEmptyUserSettingsObject(),
      disabled: true,
      debug: this.$root.debugMode,
    };
  },
  async mounted() {
    // setTimeout(async () => {
    //   if (newUserManager.currentUser && newUserManager.currentUser.userSettingsValid()){
    //     this.$router.push('/home')
    //   } else {
    //     this.loadForm();
    //   }
    // }, 200);

    let startTime = new Date();
    const loadInterval = setInterval(() => {
      if (newUserManager.currentUser && newUserManager.currentUser.companySettingsValid()) {
        this.$router.push("/home");
        clearInterval(loadInterval);
      } else {
        this.loadForm();
      }
      let currentTime = new Date();
      if (this.form) {
        this.checkDisabled();
        clearInterval(loadInterval);
      } else if (currentTime - startTime > 5000) {
        this.$router.push("/");
        clearInterval(loadInterval);
      }
    }, 300);
  },
  methods: {
    async saveSettings(e) {
      e.preventDefault();
      const response = await newUserManager.currentUser.setCompanySettings(this.form);
      if (response) {
        alert(this.t("message.settings_updated"));
      }
      this.$router.push("/home");
    },
    checkDisabled() {
      let check = false;
      if (this.form["siteId"] === "" || this.form["location"] === "") {
        check = true;
      }
      this.disabled = check;
    },
    loadForm() {
      if (newUserManager.currentUser && newUserManager.currentUser.companySettings) {
        // let emptyUserSettings = generateEmptyUserSettingsObject();
        // Object.assign(emptyUserSettings, newUserManager.currentUser.userSettings)
        let emptyCompanySettings = generateEmptyCompanySettings();
        Object.assign(emptyCompanySettings, newUserManager.currentUser.companySettings);
        this.form = emptyCompanySettings;
      }
      if (this.debug && this.form) {
        this.form.siteId = "1";
        this.form.location = "HKSTP";
      }
    },
    handleImageUpload() {
      let fr = new FileReader();
      let file = this.$refs.file && this.$refs.file.files[0] ? this.$refs.file.files[0] : undefined;

      var myImg = undefined;
      if (file) {
        fr.onload = function () {
          let img = new Image();
          img.onload = function () {
            let canvas = document.createElement("canvas");
            canvas.width = 80;
            canvas.height = 80;
            canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height);
            myImg = canvas.toDataURL();
          };
          img.src = fr.result;
        };

        fr.readAsDataURL(file);
      }

      let count = 5;
      const uploadTimer = setInterval(() => {
        if (myImg) {
          this.form.profileImage = myImg;
          clearInterval(uploadTimer);
        }
        if (count < 0) {
          alert(`Failed to upload, please try again`);
          clearInterval(uploadTimer);
        }
        count -= 1;
      }, 100);
    },
  },
  computed: {
    getAppVersion() {
      return this.$root.$data.appVersion;
    },
    formUserId() {
      if (this.form.userId) {
        return `${this.t("user_info.user_id")}: ${this.form.userId}`;
      } else {
        return "";
      }
    },
    settingsLoaded() {
      if (this.form) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.content {
  position: static;
  right: 0px;
  height: 100vh;
  min-height: max-content;
  background: #e4e3e3;
  overflow-y: scroll;
  font-size: large;
}
.align-left {
  position: relative;
  float: left;
}
.loadingMessage {
  height: 50%;
  width: 100%;
  margin-top: 50px;
  font-size: xx-large;
  font-weight: bold;
  text-align: center;
}
.subheading {
  font-weight: bold;
}
#app-version {
  color: #888888;
  font-style: italic;
  font-weight: normal;
  position: absolute;
  top: 0%;
}
.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #000000;
}
.copyright {
  position: relative;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 20px;
  z-index: 0;
}
#profile-img {
  margin: auto auto;
  margin-bottom: 5px;
  min-width: 80px;
  max-width: 80px;
}
</style>