const locales = {
  '中': {
    page: {
      dashboard: '面板',
      settings: '設置',
      help: '幫助',
      logout: '登出',
      supervisor_view: '管理員視圖',
      coming_soon: '敬請關注!<br>我們即將到來...',
    },
    guest_signin: '繼續以訪客身份登錄',
    logout_prompt: '你確定你要退出嗎？',
    instruction: {
      stay_still: '正在測量中，請保持靜止。',
      count_down1: '將在',
      count_down2: '秒後開始測量，請保持靜止。',
    },
    button: {
      mode: '顯示模式',
      fever_percentage: '臉上發燒皮膚比例',
      fever_threshold: '發燒檢查體溫',
      start: '開始',
      stop: '停止',
      shutdown: '關機',
      cancel: '取消',
      ok: '確定',
      all: '所有',
      power_on: '開機',
      full_screen: '全屏',
      save_settings: '保存設置',
      upgrade: '升級',
      change_password: '更改密碼',
      next: "下一步",
      previous: "上一步",
      finish: "完成",
      continue: '繼續',
      show_health_report: '顯示健康報告',
      clear_user_information: '清除用戶資料',
      send_to_email: '發送到郵件',
      rate_us: ' 評價我們！',
      submit: '提交',
      on: "ON",
      off: "OFF",
      start_again: "重新開始",
      back_to_homepage: "返回主頁",
      agree: "我已閱讀並同意",
      disagree: "不同意",
      immediately: '立即開始',
      try_again: '重試',
      print_result: '列印結果',
      back: "返回"
    },
    labels: {
      sending: '正在發送...',
      user_profile: '用戶資料',
      rate_us: ' 您的反饋意見對我們很重要，請給我們評個分！',
      poor: '劣',
      good: '優',
      dissatisfied: '不滿意',
      satisfied: '滿意',
    },
    graph: {
      measurement: '測量',
      time: '時間',
      day: '日',
      week: '周',
      month: '月',
      trend_line: '顯示趨勢線',
      excellent: "優秀",
      good: "良好",
      poor: "欠佳",
    },
    mode: {
      normal: '正常',
      debug: '調校'
    },
    threshold: {
      auto: '自動'
    },
    message: {
      startup: '系統已關閉。請按「開始」。',
      loading: '加載中',
      calibrating: '保持靜止，智能系統調整中 ...',
      no_face_box: '找不到人臉 ...',
      stopping: '{system_short_name} 智能系統停止中...',
      confirm_shutdown: '確定要關閉主機嗎?',
      important_message: '重要訊息',
      emmisivity: '{short_name} v{version} 是專為 Emmisivity <span class="text-danger font-weight-bold">{emmisivity}</span> 的 FLIR A315 而設計的。',
      auto_sys_restart: '自動重新啟動',
      prevent_overheat: '避免系統過熱，重新啟動中...',
      camera_error: '發現相機錯誤',
      system_error: '發現系統錯誤',
      camera_fatal_error: '因相機問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_fatal_error: '因系統發生問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_off: '系統已關閉。',
      tap_to_view: '按一下觀看。',
      condition_check_failed: '(1) 請移動進人形框內 <br/>(2) 盡量保持靜止。',
      coming_soon: '即將推出',
      breath_in: '吸',
      breath_out: '呼',
      bad_scan_message: '請重新掃描',
      weak_signal_message: '信號微弱',
      settings_updated: '設定已更新',
      password_updated: '密碼已更新',
      password_update_failed: '更改密碼失敗，請檢查您的密碼是否正確',
      fill_profile: '請填寫您的用戶資料',
      conditions: {
        lighting: "燈光",
        distance: "距離",
        centered: "置中",
        movement: "靜止",
        server_ready: "系統準備就緒"
      },
      subscribe: "訂閱以觀看內容!",
      scan_quality: "掃描質量",
      email: '電郵發送至',
      feedback: "已收到您的意見，非常感謝！祝您有個美好的一天~",
      final_check: "掃描將在 {second} 秒後開始。 請保持靜止。",
      scanning_progress: {
        title: "掃描進行時：",
        content: [
          "請企穩",
          "回答下列問題",
          "保持正常呼吸",
          "保持人臉在鏡頭中"
        ]
      },
      camera_preparation: "我們正在加載中，請確保您的臉朝向鏡頭！",
      loading_wait: "正在加載中，請耐心等候。",
      wait_result: "分析結果中…請耐心等候…",
    },
    disclaimer: {
      disclaimer1: "免責聲明：此軟件不儲存任何個人資料。",
    },
    copyright: "\u00A9 2021-2023 全境智能有限公司 版權所有",
    last_scan: '最後一次掃描',
    last_update: '最後更新',
    vs_descriptions: {
      bloodPressure: "成人的血壓小於120/80 mmHg。",
      bmi: "成人的身體質量指數在18.5與24.9之間。",
      respiratoryRate: "成人的呼吸率一般在每分鐘6至24次。",
      generalRisk: "得到心血管疾病的概率，越小越好。",
      covidRisk: "得到新冠肺炎的概率，越小越好。",
      heartRate: "成人的靜息心率在每分鐘60至100下。",
      hrvSdnn: "成人的心率變化大約是20ms或以上。",
      //      hrvSdnn: "成人一天内的心率變化大約是68-219ms之間。",
      hrvIbi: "成人的靜息IBI在600至1000 ms之間。",
      spo2: "成人的血氧量大約爲95-100%。",
      stress: "正常的壓力指數應低於2。",
      temperature: "成人的正常體溫大約是37攝氏度。",
      facialSkinAge: " 您一直都年輕美麗/帥氣!",
    },
    option: {
      yes: '有',
      no: '沒有',
      logout_yes: '是',
      logout_no: '不是',
    },
    user: {
      supervisor: '管理員',
      guest: '訪客',
    },
    company_info: {
      company_information: "公司用戶信息",
      company_id: "公司用戶編號",
      project_id: "項目編號",
      site_id: "站點編號",
      location: "站點位置",
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
    },
    user_info: {
      user_id: '用戶編號',
      profile_image: '頭像',
      personal_information: '個人信息',
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
      name: '名稱',
      nickname: '綽號',
      birthday: '生日',
      height: '身高（厘米）',
      weight: '體重（公斤）',
      gender: '出生性別',
      male: '男',
      female: '女',
      unit_system: '單位系統',
      country: '國家',
      countryOptions: {
        hongkong: "香港，中國",
        china: "中國大陸",
      },
      metric: '公制',
      imperial: '英制',
      medical_history: '病史',
      smoking: '您有吸煙嗎？',
      hypertension: '您有高血壓嗎？',
      bp_medication: '您有在服用降血壓藥物嗎？',
      diabetic: '您是糖尿病患者嗎？',
      heart_disease: '您有心臟疾病嗎？',
      depression: '您有抑鬱症嗎?',
      department: '部門',
      type_1: '類型1',
      type_2: '類型2',
      plan_type: {
        free: '免費',
        pro: "專業",
        enterprise: '企業',
        team: '團隊',
        unlimited: '無限制'
      }
    },
    password: {
      password: '密碼',
      old: '舊密碼',
      new: '新密碼',
      confirm: '確認密碼',
      not_match: '密碼不相符'
    },
    detailed_info: {
      history: '歷史數據',
      trend_analysis: '趨勢分析',
      note: '注釋',
      references: '參考資料',
      content: {
        anomalies: "在 {date}，您的 {vital_sign} 記錄有異常情況",
      },
    },
    no_data: '沒有數據',
    help_content: {
      disclaimer: {
        title: "免責聲明",
        p1: "本系統是以攝像頭為基礎的非接觸式健康監測方案，可測量生命體徵如心率、呼吸頻率、血壓和血氧飽和度等。 捕捉影像只會作計算之用，並不會存儲任何影片片段。",
        p2: "WVS適合不需要持續或密集健康監測的個人用戶，且不能用作測量生命體徵的唯一方式或取代與醫學專家的諮詢。系統的生命體徵數據不適用於醫療目的， 如果您有任何進一步的查詢，請聯繫專業的醫療人員。"
      },
      setup: {
        title: "設置指南",
        dos: {
          title: "掃描進行時，請...",
          p1: "爲了更好的結果和使用體驗，請按以下指示完成掃描：",
          1: "將攝像頭放置於視線水平附近",
          2: "不要搖晃設備",
          3: "在有充足光源的地方進行掃描，確保光線（最好室内光源）平均照到臉上",
          4: "請避免背光",
          5: "請保持臉部在綠框中間及面向鏡頭",
          6: "請確保和鏡頭之間有合適的距離"
        },
        donts: {
          title: "掃描進行時，請不要...",
          1: "不要帶口罩",
          2: "不要帶帽子",
          3: "不要被任何東西遮擋臉部",
          4: "臉上不要有不均勻的光線 （例如陽光）",
          5: "不要談話",
          6: "身體不要大幅度移動",
          7: "不要把頭轉向旁邊"
        },
        low_accuracy: {
          title: "低準確率例子",
          1: "環境太暗或太光",
          2: "光線不均或背光",
          3: "佩戴口罩或帽子或被東西遮擋臉部",
          4: "沒有面向鏡頭",
          5: "與鏡頭的距離太遠",
          6: "與鏡頭的距離太近",
        },
      },
      operation: {
        title: "操作建議",
        ground_truth: {
          title: "請先使用接觸式設備測量數據",
          p1: "在激活 WVS 系統之前，用戶應先用接觸式脈搏血氧儀作量度，再將 WVS 系統的結果與接觸式脈搏血氧儀的結果進行比較，以得知結果的可參考性。",
          p2: "請按照以下步驟以打開和操作接觸式脈搏血氧儀：",
          1: "擠壓設備背面將其打開。",
          2: "將食指或無名指插入設備，顯示屏朝上。 慣用右手的使用者，請使用右手食指或無名指。 慣用左手的使用者，請使用左手食指或無名指。",
          3: "指尖應觸碰到設備內部的手指擋塊。 如果手指沒有正確定位，請從步驟 1 再次開始。",
          4: "該設備的顯示屏上將顯示您的生命體徵",
          p3: `有關如何使用接觸式設備的更詳細說明，請參閱<a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">此處</a>的設備手冊。`
        },
        scanning: {
          title: "健康掃描步驟",
          p1: "使用 WVS 掃描系統的步驟如下：",
          1: "掃描開始前，先選擇光源充足的地方舒適地就座，確保光線（最好是室内光源）平均地照到臉上。然後將電腦放置在穩定的平面上，避免設備搖晃。",
          2: "點擊 WVS 系統左側導覽列上的主頁按鈕（第一個圖標），再點擊右上角的綠色「開機」按鈕以啟動相機和面部掃描儀系統。 該頁面應顯示「正在啟動... 」，如下圖所示。如提示允許 WVS 使用您的相機，請點擊允許。",
          caption_2: "生命體徵掃描儀啟動中",
          3: "系統準備就緒後將啟動攝像頭並顯示鏡頭畫面，同時顯示一個綠色框代表檢測到的面部範圍。請確保身體和鏡頭之間有合適的距離，保持臉部在綠框中間及面向鏡頭。",
          caption_3: "系統已啟動",
          4: "點擊綠色圓圈「開始」按鈕以開始掃描程序。 在開始掃描之前，您需要遵循掃描儀中顯示的條件。 滿足條件後，屏幕會出現三秒倒計時，表示掃描即將開始。",
          caption_4: "掃描啟動條件",
          5: "掃描進行時請跟隨綠色框下方綠色圓圈中出現的呼吸指示調整呼吸。 呼吸指示將在「吸氣」和「呼氣」之間切換，如下圖所示，您可從掃描進度圓圈中看到剩餘所需的時間。",
          note_5: "注意：如呼吸速度與指示不吻合，掃描不會檢測到錯誤，但仍建議跟隨呼吸指示以正常靜息速度呼吸。",
          caption_5: "掃描開始",
          6: "掃描會在30秒內完成，並在頁面上顯示生命體徵結果。請等待掃描完成以查看結果。",
          caption_6: "基本生命體徵",
          7: "若您想瀏覽詳細的掃描報告，請點擊導覽列上的第二個圖示前往數據板。",
          caption_7: "掃描結果概覽",
          laptop: "使用手提電腦",
          phone: "使用電話",
          pc: "使用桌上型電腦"
        }
      }
    },
    scan_tutorial: {
      title: "Vitals™ 掃描快速指南",
      dos: "請......",
      donts: "請不要......",
      step1: {
        title: "保持身體處於綠色邊框內",
        paragraph: "點擊開始按鈕後，屏幕上將出現綠色人形邊框。 邊框將指引您到正確的位置和距離使掃描更有效。 請確保您處於綠色邊框內。",
        do1: "將相機放置在視線水平附近",
        do2: "將臉部保持在邊框內並面向相機",
        do3: "確保您和相機之間距離適當",
        dont1: "不要坐在離設備太遠或太近的位置",
      },
      step2: {
        title: "確保相機平穩",
        paragraph: "相機搖晃可能會影響掃描的閱讀。請確保設備被放在平穩的位置並儘量減少移動。",
        do1: "將設備放在平穩的表面上",
        do2: "避免晃動設備",
        dont1: "不要談話",
        dont2: "身體不要大幅度移動 "
      },
      step3: {
        title: "在相機上顯示整張臉",
        paragraph: "確保您的臉完整顯示在相機上。 拿走任何會遮擋臉部的東西。",
        do1: "面向相機",
        do2: "確保您的臉沒有被帽子、連帽衫、口罩、頭髮等遮住。",
        dont1: "不要戴任何會遮住臉的東西（帽子、連帽衫、口罩）",
        dont2: "不要側過臉"
      },
      step4: {
        title: "E確保臉上的光線均勻充足",
        paragraph: "不均勻的光線可能會影響閱讀的準確性。 建議使用室內照明以更容易確保照明正確。",
        do1: "選擇光線充足的區域，確保光線（最好是室內光線）均勻地照射在臉上",
        dont1: "臉上不要有不均勻的光線（例如：陽光）",
        dont2: "背光"
      }
    },
    health_report: {
      title: "個人健康報告",
      excellent: "很好",
      high: "高",
      normal: "正常",
      low: "低",
      room_for_improvement: "有進步空間",
      good: "良好",
      poor: "不佳",
      age: "年齡",
      sex: "性別",
      email: "電郵",
      phone_number: "電話號碼",
      female: "女",
      male: "男",
      height: "身高",
      weight: "體重",
      bmi: "BMI",
      measured: "如何量度？",
      general_wellness: "綜合健康",
      stress_level: "壓力水平",
      metrics: [
        {
          name: "血壓",
          image: "blood-pressure.svg",
          tag: "bloodPressure",
          unit: "mmHg"
        },
        {
          name: "壓力",
          image: "stress.svg",
          tag: "stress",
          unit: "/5"
        },
        {
          name: "皮膚年齡",
          image: "skin-age.svg",
          tag: "facialSkinAge",
          unit: ""
        },
      ],
      health_analysis: {
        title: "健康分析",
        room_for_improvement: {
          stress: "放鬆你的心情！嘗試深呼吸，做瑜伽或跳舞以釋放壓力。好好放鬆並享受生活！",
          bloodPressure: "嘩，你的血壓正翩翩起舞！是時候放慢步伐——擁抱富有意識的時刻，散步，喝杯咖啡 ，與一本好書為伴。",
          both: "通過瑜伽、跳舞和慢生活尋找禪心，管理壓力和血壓。擁抱富有意識的時刻，散步、喝一杯咖啡和閱讀好書。"
        },
        good: "太棒了！繼續保持良好的健康習慣。嘗試透過冥想、漫步大自然和飲舒緩飲品放鬆心情。把自己的健康放在首位，在生活中找到放鬆的時間。你做得很好！",
        excellent: "向你致敬！你的血壓及壓力指數都達到了極佳的水平。繼續保持這種良好的心境！"
      },
      recommendations: {
        title: "飲品推薦",
        subtitle: "誠邀閣下到Blooms Coffee換領:",
        jasmine_tea: {
          name: "茉莉花茶",
          analysis: "有鎮靜及放鬆作用，可以改善整體健康。",
          image: "FWD_Jasmine.png"
        },
        earl_grey: {
          name: "伯爵茶",
          analysis: "有抗氧化作用，可提升免疫系統並改善整體健康。",
          image: "FWD_EarlGrey.png"
        },
        latte: {
          name: "鮮奶咖啡",
          analysis: "有減壓提神作用，其香氣及味道令人感覺愉悅，幫助舒緩壓力及疲勞。",
          image: "FWD_Latte.png"
        },
        oj: {
          name: "鮮橙汁",
          analysis: "含有豐富維生素 C及其他營養素 ，可增強抵抗力，補充水分及改善整體健康。",
          image: "FWD_OJ.png"
        },
        black: {
          name: "黑咖啡",
          analysis: "因應其飲用分量，適量的咖啡因或可有輕微降低血壓之作用。",
          image: "FWD_HotBlack.png"
        },
        cappuccino: {
          name: "泡沫咖啡",
          analysis: "濃縮咖啡、熱牛奶及泡沫的組合可製造愉悅的感覺，幫助舒緩壓力。",
          image: "FWD_Cap.png"
        },
        apple_juice: {
          name: "蘋果汁",
          analysis: "含有天然糖分，可以提供舒緩效果，有助於降低壓力水平。",
          image: "FWD_AJ.png"
        },
        english_breakfast_tea: {
          name: "英式早餐茶",
          analysis: "有提神作用，幫助提高專注力，並有助於整體健康。",
          image: "FWD_EnglishBreakfastTea.png"
        },
      },
      disclaimer: {
        title: "免責聲明",
        p1: "以攝像頭為基礎的健康監測方案設計用於非接觸式測量健康生命體徵，例如心率、呼吸頻率、血壓和 O2 飽和度。 視頻片段將通過設備的攝像頭捕捉，但不會存儲任何視頻片段。",
        p2: "該設備不能用作個人健康生命體徵測量的唯一方法，也不能取代與醫學專家的諮詢。 而且測量不用於醫療目的。 如果您有任何進一步的健康查詢，請立即聯繫醫療保健專業人員或其他醫學專家。",
        powered_by: '由',
        powered_by_2: '提供技術支持'
      },
      wait: "<br>謝謝!<br>請稍等，您的報告正在生成中⋯⋯<br>您亦同時可以在智能平板上找到您的專屬建議！",
      finish: [
        '<br>您的報告已準備完成！<br>您可以檢查您的下載檔案或',
        '按此',
        '如果您未能找到已下載檔案'
      ],
      qr_text: "掃瞄二維碼並告訴我們您對這次體驗的評價。"
    },
    start_content: {
      title: "掃描您健康: 您的AI專屬飲品​",
      subtitle: "只需幾個步驟，您就可以獲得以下結果及飲品推薦：",
      metrics: [
        {
          name: "綜合健康",
          image: "./assets/wellness.svg"
        },
        {
          name: "壓力指數",
          image: "./assets/stress.svg"
        },
        {
          name: "皮膚年齡",
          image: "./assets/skin-age.svg"
        },
        {
          name: "血壓",
          image: "./assets/blood-pressure.svg"
        }
      ],
      terms_condition: {
        title: "使用條款",
        opening: `您正在存取由全境智能有限公司（以下簡稱“全境智能”）提供的健康測試程式（以下簡稱「程式」），該公司是富衛人壽保險（百慕達）有限公司（以下簡稱「富衛」或「我們」）的服務提供商。若您使用此程式，表示您已接受並同意遵守以下使用條款，以及相關說明：`,
        content: [
          `富衛並非健康測試程式的服務提供者。我們僅提供iPad裝置讓客戶可以進行健康測試。`,
          `我們提供的指標是基於Panoptic AI的準則和標準，僅供一般健康目的使用。在任何情況下，富衛不保證內容提供的資訊和由此程式測量的健康數據之可用性、準確性和完整性。`,
          `與健康測試結果相關的任何問題或疑慮應由客戶直接向醫療專業人員諮詢。富衛對因使用程式或未尋求適當的專業建議而導致的任何後果概不負責。`,
          `此程式內的健康測試功能是透過裝置的相機鏡頭對臉部進行掃描，以非接觸式方法測量生命體徵，包括：綜合健康、壓力指數、皮膚年齡及血壓。iPad裝置上的相機鏡頭所拍攝的臉部影像和影片僅作健康數據計算之用，富衛 或 全境智能有限公司並不會收集或儲存任何臉部影像、影片片段及任何生命體徵的數據。`,
          `此程式為 Panoptic AI提供支援的健康測試，而並非醫療設備。它僅設計用於提供與您的健康有關的信息，不應被用作專業醫療建議、診斷或治療的替代品。客戶有責任就任何醫療疑慮或建議諮詢註冊醫療專業人員或醫生。客戶同意自行承擔因依賴程式生成的健康數據而產生的風險。`,
          `若使用此程式，即表示您確認您有能力獨立進行測試，並且不屬於弱勢群體，例如學齡兒童或自理能力較低的人士。`,
          `若使用此程式，即表示您確認已閱讀、理解並同意全境智能的（a）<a target="_blank" href="https://panoptic.ai/%e7%b5%82%e7%ab%af%e7%94%a8%e6%88%b6%e8%a8%b1%e5%8f%af%e5%8d%94%e8%ad%b0/">使用條款及細則</a>，（b）<a target="_blank" href="https://panoptic.ai/%e5%80%8b%e4%ba%ba%e8%b3%87%e6%96%99%e6%94%b6%e9%9b%86%e8%81%b2%e6%98%8e/">個人資料收集聲明 (PICS)</a>，及（c）<a target="_blank" href="https://panoptic.ai/%e7%a7%81%e9%9a%b1%e6%94%bf%e7%ad%96/">私隱政策</a>當中所列出之條款規範。`,
        ],
        closing: `通過使用此程式，即表示您確認已閱讀、理解並同意上述使用條款。富衛認為您使用此程式即表示接受本使用條款。如果您有任何問題或需要進一步澄清，請聯繫我們。 `,
        signing: `富衛人壽保險（百慕達）有限公司`
      },
      steps: [
        "進行臉部掃描。<br>(注意：富衛人壽及全境智能不會儲存您的面部圖像)",
        "列印結果以查看您的健康分析及推薦飲品。",
        "享受您的飲品吧！"
      ],
      warning: "如您想直接自行揀選喜愛的養生茶，歡迎使用點餐系統或向我們的服務專員尋求協助"
    },
    user_input: {
      title: "請問可以怎樣稱呼您？​",
      question: "您的名字",
    },
    quiz: [
      {
        question: "平日您會經常感到......",
        options: [
          "平靜、放鬆",
          "壓力大、疲累",
          "脾氣暴躁",
          "全部皆非 ",
        ],
      },
      {
        question: "你的睡眠質素是... ",
        options: [
          "好而且不受干擾",
          "普遍還可以",
          "淺睡而且經常失眠",
        ],
      },
      {
        question: "你容易得傷風感冒嗎？",
        options: [
          "是 (一個月一次)",
          "有時候 (三個月一次)",
          "甚少 (一年一次)"],
      },
    ],
    scan_result: {
      title: "您的評估結果如下",
      metrics: [
        {
          name: "綜合健康",
          image: "wellness.svg",
          tag: "generalWellness"
        },
        {
          name: "壓力指數",
          image: "stress.svg",
          tag: "stress"
        },
        {
          name: "皮膚年齡",
          image: "skin-age.svg",
          tag: "facialSkinAge"
        },
        {
          name: "血壓",
          image: "blood-pressure.svg",
          tag: "bloodPressure"
        }
      ],
      print_reminder: "請列印結果以查看更詳細的健康分析及我們為您推薦的飲品。"
    },
  },
  "EN": {
    page: {
      dashboard: 'Dashboard',
      settings: 'Settings',
      help: 'Help',
      logout: 'Logout',
      coming_soon: 'Stay tuned!<br>We are Coming Soon...',
    },
    guest_signin: 'Continue as Guest',
    logout_prompt: 'Are you sure you want to log out?',
    instruction: {
      stay_still: 'Scanning in progress, stay still.',
      count_down1: 'Scanning starting in ',
      count_down2: ', stay still'
    },
    button: {
      mode: 'Mode',
      fever_percentage: 'Fever Percentage',
      fever_threshold: 'Threshold Temp.',
      start: 'Start',
      stop: 'STOP',
      shutdown: 'SHUT DOWN',
      cancel: 'Cancel',
      ok: 'OK',
      all: 'ALL',
      power_on: 'POWER ON',
      full_screen: 'FULL SCREEN',
      save_settings: 'Save Settings',
      upgrade: 'Upgrade',
      change_password: 'Change Password',
      next: "Next",
      previous: "Previous",
      continue: 'Continue',
      show_health_report: 'Show Health Report',
      clear_user_information: 'Clear User Info',
      send_to_email: 'Send to Email',
      rate_us: 'Rate Us!',
      submit: 'Submit',
      on: "ON",
      off: "OFF",
      start_again: "Start Over",
      back_to_homepage: "Back to Homepage",
      agree: "I Have Read & Agree",
      disagree: "Disagree",
      immediately: 'Start Now',
      try_again: 'Try Again',
      print_result: "Print Result",
      back: "Back"
    },
    labels: {
      sending: 'sending...',
      user_profile: 'User Profile',
      rate_us: 'Your feedback is valuable to us, please give us a rating!',
      poor: 'Poor',
      good: 'Good',
      dissatisfied: 'Dissatisfied',
      satisfied: 'Satisfied',
    },
    graph: {
      measurement: 'Measurement',
      time: 'Time',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      trend_line: 'Show Trend Line',
      excellent: 'Excellent',
      good: 'Good',
      poor: 'Poor'
    },
    mode: {
      normal: 'Normal',
      debug: 'Debug'
    },
    threshold: {
      auto: 'Auto'
    },
    message: {
      startup: 'System is OFF, press "POWER ON" to start.',
      loading: 'Loading',
      calibrating: 'Stay still, Calibrating ...',
      no_face_box: 'No Face Box ...',
      stopping: 'Stopping {system_short_name}...',
      confirm_shutdown: 'Are you sure you want to shutdown the system?',
      important_message: 'Important Message',
      emmisivity: '{short_name} v{version} is designed to work with FLIR A315 with emmisivity set to <span class="text-danger font-weight-bold">{emmisivity}</span>.',
      auto_sys_restart: 'Automatic System Restart',
      prevent_overheat: 'Restarting the system to prevent over-heating...',
      camera_error: 'Camera Error Detected',
      system_error: 'System Error Detected',
      camera_fatal_error: 'System automatically stopped due to camera error, please contact {contact}. Thank you.',
      system_fatal_error: 'System automatically stopped due to error, please contact {contact}. Thank you.',
      system_off: 'System is OFF.',
      tap_to_view: 'Tap to View',
      condition_check_failed: '(1) Please move into the pur ple human <br/>(2) stay still',
      coming_soon: 'Coming Soon',
      breath_in: 'In',
      breath_out: 'Out',
      bad_scan_message: 'Bad scan, please restart the scan',
      weak_signal_message: 'Weak signal',
      settings_updated: 'Settings updated',
      password_updated: 'Password updated',
      password_update_failed: 'Failed to change password, please check your password',
      fill_profile: 'Please fill in your company profile',
      conditions: {
        lighting: "Lighting",
        distance: "Distance",
        centered: "Centered",
        movement: "Movement",
        server_ready: "Server Ready"
      },
      subscribe: "Subscribe to see this content!",
      scan_quality: "Scan Quality",
      email: 'Email sent to',
      feedback: "Feedback received, Thank You!! Have a nice day~",
      final_check: "Assessment will start in {second} seconds. Please stand still.",
      scanning_progress: {
        title: "While scanning is in progress:",
        content: [
          "Stay still",
          "Answer the question below",
          "Maintain regular breathing",
          "Ensure face is fully visible to the camera"
        ]
      },
      camera_preparation: "We are downloading some models, please ensure your face is in front of the camera!",
      loading_wait: "Loading, please wait!",
      wait_result: "Analyzing result... Please wait...",
    },
    disclaimer: {
      disclaimer1: "Disclaimer: The software does not store any personal data.",
    },
    copyright: "\u00A9 2021-2023 PanopticAI Ltd. All Rights Reserved.",
    last_scan: 'Last Scan',
    last_update: 'Last Updated',
    vs_descriptions: {
      bloodPressure: "The normal blood pressure for an adult is below 120/80 mmHg.",
      bmi: "The normal BMI for an adult is 18.5-24.9.",
      respiratoryRate: "The normal breathing rate for an adult is 6-24 bpm.",
      generalRisk: "Risk of getting cardiovascular diseases, the smaller the better.",
      covidRisk: "Risk of getting covid-19, the smaller the better.",
      heartRate: "The normal resting heart rate for an adult is between 60-100 bpm.",
      hrvSdnn: "The normal HRV (SDNN) for an adult is above ~20ms",
      //      hrvSdnn: "The normal HRV (SDNN) for an adult is 68-219 ms throughout the whole day",
      hrvIbi: "The normal resting IBI for an adult is between 600-1000 ms.",
      spo2: "The normal O2 saturation for an adult is 95-100 %.",
      stress: "The normal resting stress rating is from 0-2.",
      temperature: "The normal body temperature for an adult is around 98.6°F (37°C).",
      facialSkinAge: "You are always young & charming ;)",
    },
    option: {
      yes: 'Yes',
      no: 'No',
      logout_yes: 'Yes',
      logout_no: 'No',
    },
    user: {
      supervisor: 'Supervisor',
      guest: 'Guest',
    },
    company_info: {
      company_information: "Company Information",
      company_id: "Company Id",
      project_id: "Project Id",
      site_id: "Site Id",
      location: "Site Location",
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
    },
    user_info: {
      user_id: 'User Id',
      profile_image: 'Profile Image',
      personal_information: 'Personal Information',
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
      name: 'Name',
      nickname: 'Nickname',
      birthday: 'Birthday',
      height: 'Height (cm)',
      weight: 'Weight (kg)',
      gender: 'Gender at birth',
      male: 'Male',
      female: 'Female',
      unit_system: 'Unit System',
      country: 'Countries',
      countryOptions: {
        hongkong: "Hong Kong, China",
        china: "Mainland China",
      },
      metric: 'Metric',
      imperial: 'Imperial',
      medical_history: 'Medical History',
      smoking: 'Do you smoke?',
      hypertension: 'Do you have hypertension?',
      bp_medication: 'Are you taking blood pressure medication?',
      diabetic: 'Are you diabetic?',
      heart_disease: 'Do you have heart disease?',
      depression: 'Do you have depression?',
      department: 'Department',
      type_1: 'Type 1',
      type_2: 'Type 2',
      plan_type: {
        free: 'Free',
        pro: "Pro",
        enterprise: 'Enterprise',
        team: 'Team',
        unlimited: 'Unlimited'
      }
    },
    password: {
      password: 'Password',
      old: 'Old Password',
      new: 'New Password',
      confirm: 'Confirm Password',
      not_match: 'Password does not match'
    },
    detailed_info: {
      history: 'History',
      trend_analysis: 'Trend Analysis',
      note: 'Note',
      references: 'References',
    },
    no_data: 'No Data',
    help_content: {
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement \
        of health vital signs such as heart rate, breathing rate, blood pressure, and O2 saturation. Video \
        footage is captured through a device's camera, but no video footage is stored.",
        p2: "WVS is to be used by individuals who do not require constant or intensive health monitoring. The device \
        is not to be used as the only method for health vital signs measurement of the individuals and is not intended \
        to replace consultations with medical experts. The measurements are not served for medical purposes. Should you \
        have any further health enquiries, please contact health care professionals or other medical experts immediately."
      },
      setup: {
        title: "Set-up Guidelines",
        dos: {
          title: "Dos",
          p1: "For the best results, please do the following for the scanning:",
          1: "Put the camera near your eye level",
          2: "Prevent shaking the device ",
          3: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
          4: "Avoid backlighting ",
          5: "Keep your face well within the box and facing the camera",
          6: "Ensure suitable distance between you and the camera"
        },
        donts: {
          title: "Dont's",
          1: "Do not wear a mask",
          2: "Do not wear a hat",
          3: "Do not wear anything that will cover your face",
          4: "Do not have uneven light on you face (e.g. sunlight)",
          5: "Do not talk",
          6: "Do not make big movements",
          7: "Do not turn your head aside",
        },
        low_accuracy: {
          title: "Low Accuracy Examples",
          1: "The area is too bright or too dark",
          2: "Uneven lighting or backlighting",
          3: "The user is wearing a hat or having something cover one’s face",
          4: "The user is not facing the camera",
          5: "The distance between the user and the camera is too far",
          6: "The distance between the user and the camera is too close"
        }
      },
      operation: {
        title: "Operation",
        ground_truth: {
          title: "Measure Ground Truth with Contact-Based Device",
          p1: "The following steps should be taken before activating the WVS system so the user can compare the results from the WVS system with the results from the contact-based pulse oximeter.",
          p2: "The contact-based pulse oximeter is off until a finger is placed in it. To turn on and operate device, follow the following steps:",
          caption: `Contact-Based Device Scanning`,
          1: "Squeeze the back of the device to open it.",
          2: "Insert the index finger or ring finger into the device with the display facing up. For a left-handed person, use the left-hand index finger or ring finger. For a right-handed person, use the right-hand index finger or ring finger.",
          3: "The tip of the finger should reach the finger stop inside of the device. If the finger is not correctly positioned, repeat from Step 1.",
          4: "The device will now show your vital signs on the display.",
          p3: `For more detailed instructions on how to use the contact-based device, please refer to the manual for the device <a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">here</a>.<br>`
        },
        scanning: {
          title: "Using The Health Scanning System",
          p1: "The following steps are done to use the WVS scanning system:",
          1: "Sit comfortably in a well-lit area before scanning. Ensure the light (preferably indoor light) is evenly shed on your face. Then place the computer on a sturdy surface to prevent shaking.",
          2: `Click on the home button (the first icon) on the Navigation Bar on the left side of the WVS system. Then click on the green '<i>Power On</i>' 
          on the top right corner to start up the camera and face scanner system. The page will then display '<i>Starting up …</i>' as shown in Figure 2 below. Click allow, if prompted to enable WVS to use your camera.`,
          caption_2: "Starting up the vital sign scanner",
          3: "When the system is ready, the system will activate your device’s camera and show it in the <i>Homepage</i> along with a green box indicating your detected face. Keep your face in the center of the green frame and facing the lens while ensuring that there is an appropriate distance between your body and the camera.",
          caption_3: "Powered up system",
          4: `Click on the green circle "<i>Start</i>" button to begin the scanning process. Before beginning the scan, you will need to follow pass the <i>Scanning 
          conditions</i> shown in the face scanner and stay still while the scanner is calibrating. Once the scanning conditions are met, a three second 
          countdown will appear in the middle of the screen to show that the scan is starting.`,
          caption_4: "Conditions for scanning",
          5: `Follow the <i>Breathing guide</i> instructions that appears in the green circle throughout the scanning process. The breathing guide will change 
          between '<i>In</i>' and '<i>Out</i>' to indicate breathing in and breathing out, as shown in Figure 12 and Figure 13. The <i>Scanning progress</i> circle 
          can be seen in the scanner too. The <i>Scanning progress</i> circle indicates the remaining time till the scanning procedure is completed. `,
          caption_5: "Scanning started",
          note_5: `Note: Users are reminded to follow the in and out guide to breath at normal resting speed, although no error in the scan will be detected if the 
          breathing in and out are not matched precisely with the visual indicator.`,
          6: `The scanning progress will complete within 30 seconds, and the vital sign results will be displayed while scanning is 
          being completed. Please wait until the scan is complete to see the results. `,
          caption_6: "Basic vital signs",
          7: `To see the detailed scanning result, go to the "<i>Dashboard</i>" by clicking on the <i>Navigation bar</i>.`,
          caption_7: "Scanning results overview",
          laptop: "Using Laptop",
          phone: "Using Phone",
          pc: "Usign PC"
        }
      }

    },
    scan_tutorial: {
      title: "Quick Guide for Scanning on Vitals",
      dos: "Dos:",
      donts: "Don'ts:",
      step1: {
        title: "Position Yourself Inside The Green Border",
        paragraph: "Once you click on the start button, a green human border will appear on your screen. This will guide you for a correct position and distance to make the scanning more effective. Make sure you are positioning yourself to be inside the green border.",
        do1: "Put the camera near your eye level",
        do2: "Keep your face well within the border and facing the camera",
        do3: "Ensure suitable distance between you and the camera",
        dont1: "Do not sit too far away from or too close to the device",
      },
      step2: {
        title: "Ensure Camera is Steady",
        paragraph: "Shaky camera may affect the reading of the scanning. Please make sure that the device is placed on a steady place and do minimum movement.",
        do1: "Place device on steady surface",
        do2: "Prevent shaking the device",
        dont1: "Do not talk",
        dont2: "Do not make big movements "
      },
      step3: {
        title: "Whole Face Displayed on Camera",
        paragraph: "Make sure that your face is fully displayed on camera. Remove anything covering your face.",
        do1: "Face the camera",
        do2: "Make sure your face is not covered such as with hat, hoodie, mask, hair.",
        dont1: "Do not wear anything that cover your face (hat, hoodie, mask)",
        dont2: "Do not turn your face sideways"
      },
      step4: {
        title: "Ensure Even and Sufficient Lighthing on Your Face",
        paragraph: "Uneven lighting may affect the correctness of your reading. Indoor lighting is recommended to ensure correct lighting more easily",
        do1: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
        dont1: "Do not have uneven light on you face (e.g. sunlight)",
        dont2: "Backlighting"
      }
    },
    health_report: {
      title: "PERSONAL HEALTH REPORT",
      excellent: "Excellent",
      high: "High",
      normal: "Normal",
      good: "Good",
      low: "Low",
      room_for_improvement: "Room for Improvement",
      poor: "Poor",
      age: "Age",
      sex: "Sex",
      email: "Email",
      phone_number: "Phone Number",
      female: "Female",
      male: "Male",
      height: "Height",
      weight: "Weight",
      bmi: "BMI",
      measured: "How is it measured?",
      general_wellness: "Wellness Score",
      stress_level: "Stress Level",
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement of health vital signs such as heart rate and O<sub>2</sub> saturation. Video footage is captured through a device's camera, but no video footage is stored.",
        p2: "The device is not to be used as the only method for health vital signs measurement of the individuals and is not intended to replace conulstations with medical experts. The measurements are not served for medical purposes. Should you have any further health enquiries, please contact health care professionals or other medical experts immediately.",
        powered_by: 'Powered by ',
        powered_by_2: ""
      },
      all_good: "Hats off to you! Your blood pressure, stress level, and skin age are hitting high notes on the health-o-meter. Keep up the good vibes, you're smashing it - a true triple threat in the wellness world!",
      metrics: [
        {
          name: "Blood Pressure",
          image: "blood-pressure.svg",
          tag: "bloodPressure",
          unit: "mmHg",
          poor: "Whoa, your blood pressure's doing the cha-cha! Time to embrace slow living - think mindful strolls, decaf lattes, and chilling with a good book."
        },
        {
          name: "Stress Level",
          image: "stress.svg",
          tag: "stress",
          unit: "/5",
          poor: "Don't sweat the small stuff! Unleash your inner Zen master with deep breaths, yoga poses, or a dance-off in your living room - small steps to dial down the stress and crank up the chill vibes!"
        },
        {
          name: "Skin Age",
          image: "skin-age.svg",
          tag: "facialSkinAge",
          unit: "",
          poor: "Your skin's calling for a spa day! Whip up some hydrating masks, splash on that sunscreen, and remember, laughter's the best anti-wrinkle remedy. Let's turn that skin age dial back together!"
        },
      ],
      health_analysis: {
        title: "Health Analysis",
        room_for_improvement: {
          stress: "Unleash your Zen vibes! Deep breaths, yoga, and dance-offs in your a room to dial down stress. No sweat, just chill!",
          bloodPressure: "Whoa, your blood pressure's doing the cha-cha! Time to embrace slow living - think mindful strolls, decaf lattes, and chilling with a good book.",
          both: "Find Zen through yoga, dance-offs, and slow living to manage stress and blood pressure. Embrace mindful moments with strolls, decaf lattes, and a good book."
        },
        good: "Great job! Keep up the healthy habits and self-care. Try relaxing activities like meditation, nature walks, and soothing drinks. Make your well-being a priority and find those moments of relaxation in your everyday life. You've got this!",
        excellent: "Hats off to you! Your blood pressure and stress level are hitting high notes on the health-o-meter. Keep up the good vibes, you're smashing it - a true threat in the wellness world!"
      },
      recommendations: {
        title: "Drinks Recommendation",
        subtitle: "We cordially invite you to redeem your reward at Blooms Coffee:",
        jasmine_tea: {
          name: "Jasmine Tea",
          analysis: "Known for its calming properties, jasmine tea can help promote relaxation and overall well-being.",
          image: "FWD_Jasmine.png"
        },
        earl_grey: {
          name: "Earl Grey",
          analysis: "This black tea contains antioxidants that may support immune health and provide a sense of well-being",
          image: "FWD_EarlGrey.png"
        },
        latte: {
          name: "Latte",
          analysis: "A latte can uplift your spirits and offer comfort during stressful times. Its soothing aroma and taste create a moment of indulgence and self-care, providing much-needed relief from overwhelm and fatigue.",
          image: "FWD_Latte.png"
        },
        oj: {
          name: "Orange Juice",
          analysis: "Orange juice is packed with vitamin C and essential nutrients, supporting overall wellness, hydration, and immune system.",
          image: "FWD_OJ.png"
        },
        black: {
          name: "Black",
          analysis: "When consumed in moderation, may have a mild blood pressure-lowering effect due to their caffeine content.",
          image: "FWD_HotBlack.png"
        },
        cappuccino: {
          name: "Cappuccino",
          analysis: "The combination of espresso, steamed milk, and foam in a cappuccino can provide a soothing and enjoyable experience, helping to alleviate stress.",
          image: "FWD_Cap.png"
        },
        apple_juice: {
          name: "Apple Juice",
          analysis: "Apple juice contains natural sugars that can provide a soothing effect and help reduce stress levels.",
          image: "FWD_AJ.png"
        },
        english_breakfast_tea: {
          name: "English Breakfast Tea",
          analysis: "English Breakfast tea is a black tea blend known for its invigorating and energizing properties. It can help improve focus, boost alertness, and contribute to overall well-being.",
          image: "FWD_EnglishBreakfastTea.png"
        },
      },
      qr_text: "Scan the QR code and let us know your feedback on this experience."
    },
    start_content: {
      title: "Scan Your Health:<br>Your AI Personalized Drinks ​",
      subtitle: "In just a few steps, you can discover:",
      metrics: [
        {
          name: "Wellness<br>Score",
          image: "./assets/wellness.svg"
        },
        {
          name: "Stress<br>Level",
          image: "./assets/stress.svg"
        },
        {
          name: "Skin<br>Age",
          image: "./assets/skin-age.svg"
        },
        {
          name: "Blood<br>Pressure",
          image: "./assets/blood-pressure.svg"
        }
      ],
      terms_condition: {
        title: "Terms of Use",
        opening: `You are now accessing the wellness monitoring solution (the "Solution") powered by Panoptic AI Limited ("Panoptic AI"), a service provider of FWD Life Insurance Company (Bermuda) Limited ("FWD" or "We"). By using this Solution, you acknowledge and agree to the below terms of use, as well as the explanations`,
        content: [
          `FWD is not the service provider for the wellness monitoring solution. We only provide the iPad device for customers to conduct wellness tests at FWD premises.`,
          `The indicators that we deliver are based on the guidelines and standards at Panoptic AI and are exclusively for general wellness purposes, without any form of guarantee.FWD do not guarantee the availability, accuracy and completeness of the information provided by the Solution and the wellness data measured through this Solution in any circumstances.`,
          `Any issues or concerns related to the wellness test should be directed to the medical professionals by Customer.FWD shall not be liable for any consequences arising from and consequential or incidental to the use of the Solution or any failure to seek appropriate professional advice.`,
          `The Solution is designed to scan the face using the built-in device’s camera to measure vital signs through a contactless method.These vital signs include wellness score, stress level, skin age, and blood pressure.Facial image and video footage will be captured through the camera on the iPad device for the purpose of wellness data calculation only, no facial image, video footage and wellness data regarding the vital signs will be collected and stored by FWD or Panoptic AI.`,
          `The Solution powered by Panoptic AI is not a medical device but a wellness monitoring solution.It is designed to provide information related to your wellness only, it should not be used as a substitute for professional medical advice, diagnosis, or treatment.It is the Customer’s responsibility to consult a registered healthcare professional or medical practitioner for any medical concerns or advice.Customers agree that any reliance on the wellness data generated by the iPad device is at his/ her own risk.`,
          `By using this Solution, you confirm that you are capable of conducting the test independently and do not belong to vulnerable groups, such as children of school age or individuals who are less capable of managing their own affairs.`,
          `By using this Solution and providing your personal information, you acknowledge and agree to accept the aforementioned (a) the <a target="_blank" href="https://panoptic.ai/end-user-licence-agreement/">T&C</a>, (b) <a target="_blank" href="https://panoptic.ai/personal-information-collection-statement/">PICS</a>, and (c) <a target="_blank" href="https://panoptic.ai/privacy-policy/">Privacy Policy</a> of Panoptic AI.`,
        ],
        closing: `By using the Solution, you acknowledge that you have read, understood, and agreed to the Terms of Use above. FWD assumes the Customer's use of the iPad device indicates due acceptance of this Term of Use. Please contact us if you have any questions or require further clarification.`,
        signing: `FWD Life Insurance Company (Bermuda) Limited`
      },
      steps: [
        "Undergo a facial scan.<br>(Note: FWD Life & Panoptic AI will not store your image)​",
        "Print out the result to view your health assessment and drinks recommendation.",
        "Enjoy your drinks!​"
      ],
      warning: "If you would like to choose your favourite tea directly, please use the ordering system or ask our service specialists for assistance."
    },
    user_input: {
      title: "How can I call you ?",
      question: "Your Name",
    },
    quiz: [
      {
        question: "Usually you feel......",
        options: [
          "Calm, relaxed",
          "Stressed, tired",
          "Quick to anger",
          "None of the above",
        ],
      },
      {
        question: "Your sleeping quality is...",
        options: [
          "Good and uninterrupted",
          "Basically fine",
          "Unsatisfactory with constant insomnia",
        ],
      },
      {
        question: "Do you catch cold or flu easily?",
        options: [
          "Yes (Once a month)",
          "Sometimes (Once in 3 months)",
          "Rarely (Once a year)"
        ],
      },
    ],
    scan_result: {
      title: "Your health assessment results are in!",
      metrics: [
        {
          name: "Wellness<br>Score",
          image: "wellness.svg",
          tag: "generalWellness",
        },
        {
          name: "Stress<br>Level",
          image: "stress.svg",
          tag: "stress"
        },
        {
          name: "Skin<br>Age",
          image: "skin-age.svg",
          tag: "facialSkinAge"
        },
        {
          name: "Blood<br>Pressure",
          image: "blood-pressure.svg",
          tag: "bloodPressure"
        }
      ],
      print_reminder: "Please print the result to view more detailed health<br>assessment and the drinks we recommend for you."
    },
  },
};

module.exports = locales;
