// https://css-tricks.com/building-progress-ring-quickly/

<template>
  <div class="d-flex justify-content-center " id="circular-progress" v-if="startBreathing">
    <div class="my-auto">
      <svg class="circle" :height="(radius + stroke) * 2" :width="(radius + stroke) * 2">
        <circle
          class="progress-background"
          stroke="#E87722"
          fill="transparent"
          :stroke-width="stroke"
          :r="radius"
          :cx="radius + stroke"
          :cy="radius + stroke"
        />
        <circle
          class="progress"
          stroke="#E87722"
          fill="transparent"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset }"
          :stroke-width="stroke"
          :r="radius"
          :cx="radius + stroke"
          :cy="radius + stroke"
        />
        <text x="50%" y="50%" text-anchor="middle" dy=".3em">{{progress}}%</text>
        <!-- <circle class="breathing-circle-animation" 
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
          stroke="white"
          fill="white"
        /> -->
      </svg>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import VitalsConfig from "../../plugin/plugin_configuration.js";
import { ref } from "vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      progress: ref(0),
    };
  },
  props: {
    radius: Number,
    stroke: Number,
    timeLeft: { type: Number, default: null },
    text: String,
  },
  data() {
    const circumference = this.radius * 2 * Math.PI;

    return {
      startBreathing: false,
      circumference,
      width: window.innerWidth,
    };
  },
  methods: {
  },
  created() {
    this.totalTime = VitalsConfig.Plugin.scanTime;
  },
  computed: {
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
    inOutTextStyle() {
      var _in = this.$t("message.breath_in");
      var _out = this.$t("message.breath_out");
      return `--in:"${_in}"; --out:"${_out}";`;
    },
  },
  watch: {
    timeLeft(newValue, oldValue) {
      if (oldValue == null && newValue !== null) {
        this.startBreathing = true;
      }
      if (newValue === null) {
        this.progress = 0;
        this.state = "idle";
        this.startBreathing = false;
      } else {
        this.progress = Math.round(((this.totalTime - this.timeLeft) / this.totalTime) * 100.0);
      }
    }
  },
};
</script>


<style scoped>
#circular-progress {
  position: absolute;
  width: 100%;
  bottom: 80px;
}
.d-flex {
  gap: 80px;
}
.description {
  font-size: 45px;
}
.progress {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.container {
  display: flex;
}
.text {
  content: v-bind(inOutTextStyle);
  position: absolute;
  top: 33%;
  left: 160px;
}
text {
  font-size: 80px;
  color: #183028;
}
/* p {
    position: relative;
    left: -50%;
    transform: translate(0%, -50%);
    font-size: 1.5em;
    color: white;
} */
.progress-background {
  opacity: 0.1;
}

@keyframes textAnimation {
  0% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--in);
  }
  45% {
    opacity: 1;
    font-size: 2.9em;
    content: var(--in);
  }
  46% {
    opacity: 1;
    font-size: 2.9em;
    content: var(--out);
  }
  90% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--out);
  }
  91% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--in);
  }
  100% {
    opacity: 1;
    font-size: 1.2em;
    content: var(--in);
  }
}
@keyframes textAnimation-mobile {
  0% {
    opacity: 1;
    font-size: 1em;
    content: var(--in);
  }
  45% {
    opacity: 1;
    font-size: 1.5em;
    content: var(--in);
  }
  46% {
    opacity: 1;
    font-size: 1.5em;
    content: var(--out);
  }
  90% {
    opacity: 1;
    font-size: 1em;
    content: var(--out);
  }
  91% {
    opacity: 1;
    font-size: 1em;
    content: var(--in);
  }
  100% {
    opacity: 1;
    font-size: 1em;
    content: var(--in);
  }
}
@keyframes circleAnimation {
  0% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.4);
  }
  50% {
    opacity: 0.9;
    fill: #00cc00;
    transform: scale(1);
  }
  100% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.4);
  }
}
@keyframes circleAnimation-mobile {
  0% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.9;
    fill: #00cc00;
    transform: scale(0.9);
  }
  100% {
    opacity: 0.75;
    fill: #ffaa00;
    transform: scale(0.3);
  }
}

.breathing-circle-animation {
  opacity: 1;
  position: relative;
  left: 50%;
  top: 50%;
  color: white;
}

@media (max-width: 2000px) {
  .d-flex {
    gap: 10px;
  }
  .description {
    font-size: 18px;
  }
  text {
    font-size: 40px;
  }
  #get-vs-button-container {
    width: 90%;
    padding: 20px;
    top: 200px;
  }
}

@media (max-width: 1024px) {
  #get-vs-button-container {
    top: 160px;
  }
}
</style>